import {
  Center,
  Heading,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Button,
  ModalFooter,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';

function ComboModal(props) {
  console.log(props);

  const disclosure = props.disclosure;
  const combo = props.combo;
  const { user, setUser } = useContext(AccountContext);

  const useProcedimentos =
    combo && combo.procedimentos
      ? combo?.procedimentos?.map((procedimento) => {
        return (
          <Text key={procedimento.id} fontSize="sm">
            {procedimento.nome}
          </Text>
        );
      })
      : null;

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#5AB2AD" mt="5" size="md">
                Itens
              </Heading>
            </Center>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <VStack align="start" w="80%" p="10%" maxH="60vh" overflowY="auto">
                {useProcedimentos}
              </VStack>
              <Button
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  console.log("mudei")
                  props.change([...combo.procedimentos]);
                  setUser((prevState) => ({
                    ...prevState,
                    cart: {
                      itens: prevState.cart?.itens ? [...prevState?.cart?.itens, ...combo.procedimentos] : [...combo.procedimentos],
                      prescricao: prevState.cart?.prescricao ? [...prevState.cart.prescricao] : [],
                    },
                  }));
                  disclosure.onClose();
                }}
              >
                Adicionar
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ComboModal;
