import {
    Drawer,
    DrawerBody,
    DrawerContent,
    Button,
    DrawerHeader,
    HStack,
    ButtonGroup,
    DrawerOverlay,
    PinInput,
    PinInputField,
    Text,
    VStack,
    useToast,
    Radio,
    useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ExameAction from '../../../Utils/Drawer/ExameAction'
import ActionChoice from '../../../Utils/Drawer/ExameAction';
function ConfirmacaoWhatsappPaciente(prop) {
    const disclosure = prop.disclosure;
    const userData = prop.paciente_data;
    console.log(userData)
    const [error, setError] = useState(null);
    const [pin, setPin] = useState('');
    const type = prop.type;
    const navig_to = prop.navig_to;
    const navigate = useNavigate();
    const actionDisclosure = useDisclosure();
    const handleChange = (pin) => {
        setPin(pin);
    };
    const toast = useToast();
    function callToast(title, description, status) {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 4000,
            isClosable: true,
            position: 'top',
            colorScheme: 'red',
            containerStyle: {
                paddingTop: '50px',
            },
        });
    }
    const nome_splitado = userData?.nome ? userData?.nome?.split(' ') : ''
    const cpf_splitado = userData?.cpf ? userData?.cpf?.slice(-3) : ''
    return (
        <>
            <VStack>
                <ActionChoice disclosure={actionDisclosure} />
                <Drawer placement={'bottom'} isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
                    <DrawerOverlay />
                    <DrawerContent backgroundColor="#5AB2AD" height={{ md: '45%', sm: '50%', lg: '50%' }} borderTopRadius={'3.2em'} p="15% 5%">
                        <DrawerHeader>
                            <Text fontSize="0.6em" lineHeight="1em" color="#FFFFFF" fontWeight={'bold'} textAlign={'center'} noOfLines={4} maxWidth="100%">
                                Digite abaixo o código de confirmação enviado para o novo whatsapp cadastrado do paciente {nome_splitado[0]} com CPF de final {cpf_splitado}
                            </Text>
                        </DrawerHeader>
                        <DrawerBody>
                            <Formik
                                initialValues={false}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values, actions) => {
                                    const vals = {
                                        codigo: pin,
                                        fone: userData.whatsapp,
                                        medico: true,
                                        documento: userData.cpf,
                                    };
                                    console.log('vals', vals);
                                    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/confirm_whatsapp`, {
                                        method: 'POST',
                                        credentials: 'include',
                                        headers: {
                                            'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify(vals),
                                    })
                                        .catch((err) => {
                                            console.log('err', err);
                                            return;
                                        })
                                        .then((res) => {
                                            if (!res || !res.ok || res.status >= 400) {
                                                setError('Erro na Rede', res);
                                                return res.json();
                                            }
                                            if (res.status === 200) {
                                                console.log('res', res);
                                                setError('');
                                            }
                                            return res.json();
                                        })
                                        .then((data) => {
                                            console.log('data', data);
                                            if (!data) return;
                                            if (data.success === false) {
                                                callToast('Importante', 'Codigo Incorreto. Tente novamente', 'error');
                                                setPin('');
                                                return;
                                            }
                                            if (data.success === true) {
                                                setPin('');
                                                console.log('sucesso');
                                                const vals = {
                                                    whatsapp: userData.whatsapp,
                                                    paciente_id: userData.paciente_id,
                                                }
                                                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/updatePacienteWhatsapp`, {
                                                    method: 'POST',
                                                    credentials: 'include',
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                    },
                                                    body: JSON.stringify(vals),
                                                }).then(async (response) => {
                                                    return response.json()
                                                }).then(async (resposta) => {
                                                    if (resposta.message !== 'failed') {
                                                        callToast('Importante', 'Sucesso ao atualizar o contato do paciente', 'success');
                                                        disclosure.onClose()
                                                        actionDisclosure.onOpen()
                                                    } else {
                                                        callToast('Importante', 'Falha ao atualizar o contato do paciente. Tente novamente', 'error');
                                                        setPin('');
                                                    }
                                                })
                                            }
                                        });
                                }}
                            >
                                <VStack as={Form}>
                                    <Radio w="3em" autoFocus hidden></Radio>
                                    <HStack w="100%">
                                        <PinInput size="md" name="codigo" value={pin} onChange={handleChange} type='alphanumeric'>
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                            <PinInputField backgroundColor={'#ffff'} h="2.5em" />
                                        </PinInput>
                                    </HStack>
                                    <ButtonGroup flexDirection={'column'} w="70%" pt="15%" ml="15%" >
                                        <Button backgroundColor="#ffff" color="#5AB2AD" h="3em" type="submit" borderRadius="2em">
                                            Confirmar
                                        </Button>
                                    </ButtonGroup>
                                </VStack>
                            </Formik>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </VStack>
        </>
    )
}

export default ConfirmacaoWhatsappPaciente