import { Center, HStack, Img, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';
import Folder from '../../../assets/images/icons/folder.svg';
import DrawerCarrinho from '../Drawer/DrawerCarrinho';
import MenuIcon from '../../../assets/images/icons/menu-icon.svg';
import MainDrawer from '../../Menu/MainDrawer';
import { useEffect } from 'react';
import { useState } from 'react';

function DoctorHeader(props) {
  const navigate = useNavigate();
  const { page, user, menuDisclosure } = useContext(AccountContext);
  const [count, setCount] = useState(0);
  let url = window.location.href;
  const disclosureCarrinho = useDisclosure();
  useEffect(() => {
    let countPrescricao = user.cart && user.cart.itens && user.cart.itens.length ? user.cart?.itens?.length : 0;
    let countExame = user.cart && user.cart.prescricao && user.cart.prescricao.length ? user.cart?.prescricao?.length : 0;
    let countAtestado = user.cart && user.cart.atestado && user.cart.atestado.length ? user.cart?.atestado?.length : 0;
    let countConsulta = user.cart && user.cart.consultas && user.cart.consultas.length ? user.cart?.consultas?.length : 0;
    let countAreaLivre = user.cart && user.cart.arealivre && user.cart.arealivre.length ? user.cart?.arealivre?.length : 0;
    setCount(countPrescricao + countExame + countAtestado + countConsulta + countAreaLivre);
  }, [user.cart]);
  return (
    <>
      <MainDrawer disclosure={menuDisclosure} />
      <DrawerCarrinho disclosure={disclosureCarrinho} />
      <VStack shadow="md" h="9em" backgroundColor="#5AB2AD" mb="5%">
        <HStack pt="5em" w="85%">
          <Img
            src={MenuIcon}
            alt="menu"
            w="2em"
            top="10"
            position="absolute"
            onClick={() => {
              menuDisclosure.onOpen();
            }}
          />
          <IoArrowBackCircleOutline w="20%" size="2rem" color="#95F3EE" onClick={() => navigate(-1)} />
          <Text color="white" textAlign="center" fontWeight="bold" w="80%" fontSize="xl">
            {page}
          </Text>
          { url.includes('/solicitacao/exame') || url.includes('/solicitacao/prescricao') || url.includes('/solicitacao/atestado') || url.includes('/consulta') || url.includes('/solicitacao/area-livre')  ? (
            <>
              <Img
                src={Folder}
                boxSize="1.5em"
                onClick={() => {
                  disclosureCarrinho.onOpen();
                }}
                color="orange"
                position="absolute"
                right="5"
                zIndex="101"
              />

              {user.cart ? (
                <Center
                  zIndex="101"
                  borderRadius="50%"
                  w="1em"
                  h="1em"
                  position="absolute"
                  right="4"
                  top="20"
                  backgroundColor="#95F3EE"
                  color="white"
                >
                  <Text fontSize="0.8em" color="#5AB2AD">
                    {count}
                  </Text>
                </Center>
              ) : null}
            </>
          ) : null}
        </HStack>
      </VStack>
    </>
  );
}

export default DoctorHeader;
