import React, { useContext, useEffect, useState } from 'react';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import { AccountContext } from '../AccountContext';
import { Button, ButtonGroup, Image, Text, VStack, Wrap, chakra, useToast } from '@chakra-ui/react';
import TextField from '../Utils/Inputs/TextField';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import voltar from '../../assets/images/v.svg';

function RecuperacaoSenha() {
  const { setUser, setPage } = useContext(AccountContext);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  setPage('Recuperação de senha');

  function enviarWpp(whatsapp, cpf, nome) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/recuperacaodesenha/${whatsapp}/${cpf}/${nome}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }

  return (
    <>
      <VStack align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
        <Image mr="18em" src={voltar} onClick={() => navigate(-1)} size="lg"></Image>{' '}
        <Formik
          initialValues={{}}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            let cpf = onlyNumbers(vals.username);
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/searchOperatorByCPF/${cpf}`, {
              method: 'post',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .catch((err) => {
                return err;
              })
              .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                  console.log(res);
                  setError('Não foi possível completar a consulta do seu cpf nesse momento');
                  return;
                }
                return res.json();
              })
              .then((result) => {
                if (!result.data[0]) {
                  setError('Não encontramos o seu CPF na nossa base de clientes. Faça seu cadastro para continuar.');
                } else {
                  if (!result?.data[0]?.whatsapp) {
                    callToast('Importante', 'Não foi possível localizar um numero de whatsapp vinculado a esta conta. Entre em contato com o suporte', 'error');
                    return
                  }
                  let operador_id = result.data[0].operador_id
                  let whatsapp = result.data[0].whatsapp;
                  let cpf = onlyNumbers(vals.username);
                  let nome = result.data[0].nome;

                  enviarWpp(whatsapp, cpf, nome);
                  navigate('/mensagemRecuperacao', {
                    state: {
                      cpf: cpf,
                      whatsapp: whatsapp,
                      nome: nome,
                      operador_id: operador_id,
                    },
                  });
                }
              });
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          <VStack as={Form} w="100%">
            <VStack w="75%" pt="20%">
              <chakra.p color="#318681" fontSize={'1em'} fontWeight={'bold'} paddingBottom="0.5em">
                Digite o seu CPF para recuperar sua senha
              </chakra.p>
              <chakra.h1 fontSize="lg" color="#318681" fontWeight="bold" ml="10em">
                CPF
              </chakra.h1>
              <TextField
                name="username"
                placeholder="Digite seu CPF"
                textAlign="center"
                fontSize="0.7em"
                color="gray"
                backgroundColor="#FFFFFF"
                mask="999.999.999-99"
                maskChar={null}
                type="tel"
                borderRadius="0.6em"
                h="3.9em"
                fontWeight="bold"
                autoComplete="off"
              />
            </VStack>

            <VStack w="75%">
              <Text as="p" color="red.500" onClick={() => (window.location.href = 'https://wa.me/558881189898')}>
                {error}
                <Wrap pl="5px" color="green.500">
                  {error2}
                </Wrap>
              </Text>
            </VStack>

            <ButtonGroup flexDirection={'column'} pt={{ md: '16em', sm: '35%' }} w="75%">
              <Button backgroundColor="#5AB2AD" color="#FFFFFF" h="2.9em" type="submit" borderRadius="0.8em">
                Continuar
              </Button>
            </ButtonGroup>
            <chakra.h1 paddingTop="5%" fontSize={'0.8em'} color="#5AB2AD" fontWeight={'bold'} onClick={() => navigate('/CNPJ')}>
              Quero me cadastrar
            </chakra.h1>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default RecuperacaoSenha;
