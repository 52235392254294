import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Button,
  DrawerHeader,
  HStack,
  ButtonGroup,
  DrawerOverlay,
  chakra,
  Text,
  VStack,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import TextField from '../../Inputs/TextField';
import ActionChoice from '../../../Utils/Drawer/ExameAction';

function EnderecoPaciente(prop) {
  const disclosure = prop.disclosure;
  const actionDisclosure = useDisclosure();
  const [cep_clinica, setCep] = useState();
  const [location, setLocation] = useState();
  const [error, setError] = useState(null);
  const toast = useToast();
  const { state } = useLocation();
  const { paciente } = state;
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  console.log(paciente);

  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function buscarCep(cep, setFieldValue) {
    const validate = onlyNumbers(cep);
    setCep(validate);
    try {
      if (validate.length >= 8) {
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/patients/getAdressByCEP/${validate}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .catch((err) => {
            console.log(err);
            return;
          })
          .then((response) => {
            if (!response || !response.ok || response.status >= 400) {
              return;
            }
            return response.json();
          })
          .then((result) => {
            setLocation(result.data);
            setFieldValue('rua', result.data.logradouro);
            setFieldValue('bairro', result.data.bairro);
            setFieldValue('uf', result.data.uf);
            setFieldValue('cidade', result.data.localidade);
            setFieldValue('complemento', result.data.complemento);
            setFieldValue('municipio_id', result.data.ibge);
          });
      }
    } catch (error) {
      console.log(error);
      setError('Não foi possivel consultar seu cep, verifique o cep informado.');
    }
  }

  return (
    <>
      <VStack>
        <Drawer placement={'bottom'} isOpen={disclosure.isOpen}>
          <DrawerOverlay />
          <DrawerContent backgroundColor="#ffff" height={{ md: '80%', sm: '90%', lg: '50%' }} borderTopRadius={'3.2em'} p="10% 5%">
            <DrawerHeader>
              <Text fontSize="0.6em" lineHeight="1em" color="black" fontWeight={'bold'} textAlign={'center'} noOfLines={4} maxWidth="100%">
                Digite o endereço do paciente
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  cep: '',
                  rua: '',
                  numero: '',
                  bairro: '',
                  uf: '',
                  cidade: '',
                  complemento: '',
                  cidade: '',
                  uf: '',
                }}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  const vals = { ...values };
                  fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/patients/updateClientAdress`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                      paciente_id: paciente.paciente_id,
                      cep: vals.cep,
                      rua: vals.rua,
                      numero: vals.numero,
                      bairro: vals.bairro,
                      uf: vals.uf,
                      cidade: vals.cidade,
                      complemento: vals.complemento,
                      municipio_id: location.ibge ? location.ibge : '',
                    }),
                  })
                    .catch((err) => {
                      console.log(err);
                      return err;
                    })
                    .then((response) => {
                      if (!response || !response.ok || response.status >= 400) {
                      }
                      return response.json();
                    })
                    .then((result) => {
                      console.log(result);
                      if (!result) return;

                      if (result.message === 'sucesso') {
                        disclosure.onClose();
                        toast({
                          title: 'Prescrição Adicionada',
                          position: 'bottom',
                          description: '',
                          status: 'success',
                          duration: 2000,
                          isClosable: true,
                          containerStyle: {
                            paddingBottom: '3em',
                          },
                        });
                        actions.resetForm();
                      }
                    });
                }}
              >
                {(props) => {
                  const { setFieldValue } = props;
                  return (
                    <VStack as={Form}>
                      <TextField
                        label="CEP*"
                        name="cep"
                        placeholder=""
                        mask="99999-999"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="tel"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        w="100%"
                        onKeyUp={(e) => {
                          buscarCep(e.target.value, setFieldValue);
                        }}
                      />
                      <HStack w="100%">
                        <TextField
                          name="rua"
                          label="Rua*"
                          placeholder=""
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="text"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />
                      </HStack>

                      <HStack>
                        <TextField
                          name="numero"
                          label="N°"
                          placeholder=""
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="tel"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />

                        <TextField
                          name="bairro"
                          label="Bairro*"
                          textAlign="left"
                          fontSize="0.7em"
                          placeholder=""
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="text"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />
                      </HStack>

                      <HStack>
                        <TextField
                          name="uf"
                          label="UF*"
                          textAlign="left"
                          fontSize="0.7em"
                          placeholder=""
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="text"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />

                        <TextField
                          name="cidade"
                          label="Cidade*"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          placeholder=""
                          maskChar={null}
                          type="text"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />
                      </HStack>

                      <HStack w="100%">
                        <TextField
                          name="complemento"
                          label="Complemento*"
                          placeholder=""
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="text"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                        />
                      </HStack>
                      <chakra.p color="red" fontSize={{ md: '0.8em', sm: '0.6em', lg: '50%' }}>
                        Para cada medicamento de controle especial prescrito, o paciente receberá 1 receita.
                      </chakra.p>
                      <ButtonGroup flexDirection={'column'} w="70%" pt={{ md: '25%', sm: '5%', lg: '50%' }}>
                        <Button backgroundColor="#5AB2AD" color="#ffff" h="3em" type="submit" borderRadius="0.8em">
                          Confirmar
                        </Button>
                      </ButtonGroup>
                    </VStack>
                  );
                }}
              </Formik>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </VStack>
    </>
  );
}

export default EnderecoPaciente;
