import { Img, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { AccountContext } from '../AccountContext';
import Spin from '../../assets/images/spin.svg'
import './loading.css';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
function Loading() {
    const { user } = useContext(AccountContext);

    return (
        <>
            <NoUserHeader />
            <VStack
                w={{
                    base: '20%',
                    md: '100px',
                }}
                m="50% auto"
                justify="center"
            >
                <Img size="sm" margin="auto" src={Spin} className="rotate" />
            </VStack>
        </>
    )
}

export default Loading