import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Flex,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import Voltar from '../../assets/images/voltar.svg';
import { React, useContext, useState } from 'react';
import { AccountContext } from '../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import Medicos from '../../assets/images/medicos.png';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import ConfirmacaoWhatsapp from '../Utils/Modal/Cadastro/ConfirmacaoWhatsapp';
import * as Yup from 'yup';
import { parse } from 'date-fns';

function CadastroClinica() {
  const { setUser, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const toast = useToast();
  const confirmarWpp = useDisclosure();
  const { state } = useLocation();
  const [telefone, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  setPage('Cadastro');
  const [telefoneMask, setTelefoneMask] = useState('(99) 99999-9999');
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  function enviarWpp(wpp, nome, documento, medico) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/confirma_wpp_empresa/${wpp}/${nome}/${documento}/${medico}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  // function callToast(title, description, status) {
  //   toast({
  //     title: title,
  //     description: description,
  //     status: status,
  //     duration: 4000,
  //     isClosable: true,
  //     position: 'bottom',
  //     colorScheme: 'red',
  //     containerStyle: {
  //       paddingTop: '50px',
  //     },
  //   })
  // }

  function applyMask(telefone) {
    if (!telefone) return '';
    const cleanedValue = telefone.replace(/\D/g, '');

    if (cleanedValue.startsWith('0800')) {
      return cleanedValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (cleanedValue.length <= 8) {
      return cleanedValue.replace(/(\d{4})(\d{3})(\d{1,4})$/, '$1-$2-$3');
    } else if (cleanedValue.length === 11) {
      return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (cleanedValue.length >= 10) {
      return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      return cleanedValue;
    }

  }


  return (
    <>
      <NoUserHeader />
      <ConfirmacaoWhatsapp disclosure={confirmarWpp} paciente_data={userData} navig_to="/dadosclinicos" />
      <VStack w="100%">
        {' '}
        <Formik
          initialValues={{ razao_social: '', nome_fantasia: '', whatsapp_clinica: '', data_abertura: '', telefone_clinica: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            razao_social: Yup.string()
              .required('Razão social é obrigatória!')
              .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Razão social não deve conter caracteres especiais, exceto números'),

            nome_fantasia: Yup.string()
              .required('Nome fantasia é obrigatório')
              .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Nome fantasia não deve conter caracteres especiais, exceto números'),

            telefone_clinica: Yup.string()
              .required('Telefone é obrigatório')
              .test('validacao-telefone', 'Telefone Inválido', function (value) {
                if (!value) return false;

                const cleanedValue = value.replace(/\D/g, '');

                if (cleanedValue.startsWith('0800')) {
                  return true;
                }

                if (cleanedValue.startsWith('0')) {
                  return false;
                }

                if (/^(\d)\1+$/.test(cleanedValue)) {
                  return false;
                }

                return true
              }).min(8, 'O telefone deve ter no mínimo 8 dígitos'),

            whatsapp_clinica: Yup.string()
              .required('Whatsapp é obrigatório')
              .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),

            data_abertura: Yup.date()
              .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
              .required('Data de abertura é obrigatória!')
              .typeError('Data inválida, digite uma data no formato DD/MM/AAAA')
              .test('minDate', 'A data de abertura deve ser a partir de 1900', function (value) {
                const minDate = new Date(1900, 0, 1);
                return value >= minDate;
              })
              .test('noFutureDate', 'A data de abertura não pode ser uma data futura', function (value) {
                const currentDate = new Date();
                return value <= currentDate;
              }),

          })}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            const medico = false;
            setUserData({
              data_abertura: vals.data_abertura.replace(/\//g, '-'),
              razao_social: vals.razao_social,
              nome_fantasia: vals.nome_fantasia,
              documento: onlyNumbers(state.cnpj),
              medico,
              telefone_clinica: onlyNumbers(vals.telefone_clinica),
              whatsapp_clinica: onlyNumbers(vals.whatsapp_clinica),
            });
            const wpp = vals.whatsapp_clinica;
            const documento = state.cnpj;
            const nome = vals.nome_fantasia;

            enviarWpp(onlyNumbers(wpp), nome, onlyNumbers(documento), medico);
            setLoading(true);
            confirmarWpp.onOpen();
          }}
        >
          {(props) => {
            const { setFieldValue } = props;
            return (
              <VStack as={Form} w="100%">

                <VStack w="90%" textAlign={'center'}>
                  <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                    Aqui você irá cadastrar os dados da clínica
                  </Text>
                  <TextField
                    label="Razão social*"
                    name="razao_social"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="text"
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    w="100%"
                    autoComplete="off"
                    maxLength={50}
                  />
                  <TextField
                    name="nome_fantasia"
                    label="Nome fantasia*"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="text"
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    autoComplete="off"
                    maxLength={50}
                  />

                  {/* <HStack flexDirection={'row'}> */}
                  {/* <TextField
                  name="cnpj"
                  label="CNPJ*"
                  placeholder=""
                  mask="99.999.999/9999-99"
                  textAlign="left"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="tel"
                  h="3.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                /> */}

                  <TextField
                    name="data_abertura"
                    label="Data de abertura*"
                    textAlign="left"
                    fontSize="0.7em"
                    color="#939598"
                    backgroundColor="#EFEFEF"
                    maskChar={null}
                    type="tel"
                    h="3.8em"
                    fontWeight="bold"
                    borderRadius="-0.5em"
                    autoComplete="off"
                    mask="99/99/9999"
                  />
                  {/* </HStack> */}

                  <HStack w="100%">
                    <TextField
                      name="telefone_clinica"
                      label="Telefone*"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                      value={applyMask(telefone)}
                      onChange={(e) => {
                        const unmaskedValue = e.target.value.replace(/\s/g, '');
                        if (unmaskedValue.length <= 14) {
                          setValue(unmaskedValue);
                          setFieldValue('telefone_clinica', unmaskedValue);
                        }
                      }}
                    // maxLength={13}
                    />

                    <TextField
                      name="whatsapp_clinica"
                      label="Whatsapp*"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      autoComplete="off"
                      mask="(99) 99999-9999"
                    />
                  </HStack>

                  <ButtonGroup flexDirection={'column'} w="70%" pt="20%">
                    <Button backgroundColor="#95F3EE" type="submit" color="#5AB2AD" h="3em" borderRadius="2em" >
                      Seguir
                    </Button>
                  </ButtonGroup>
                </VStack>

              </VStack>
            );
          }}
        </Formik>
      </VStack>
    </>
  );
}

export default CadastroClinica;
