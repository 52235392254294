import {
  Button,
  ButtonGroup,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
import { useLocation, useNavigate } from 'react-router-dom';
function EmpresaJaCadastrada(prop) {
  const disclosure = prop.disclosure;
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered>
        <ModalOverlay />
        <ModalContent height="350px" width="350px" borderRadius="1.5em">
          {/* <ModalHeader>
            <Image ml="280" src={sair} size="lg" onClick={disclosure.onClose}></Image>
          </ModalHeader> */}

          <ModalBody pt="2.5em">
            {/* <Image ml="120" src={png} size="lg"></Image> */}
            <Heading size="md" ml="3" mt="2.5em">
              <Text fontSize="1em" lineHeight="1em" color="#939598" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Opa! Você já possui cadastro conosco. Faça login!
              </Text>
            </Heading>
            <VStack>
              <ButtonGroup flexDirection={'column'} w="70%" pt="20%">
                <Button backgroundColor="#95F3EE" type="submit" onClick={() => navigate('/login')} color="#5AB2AD" h="3em" borderRadius="2em">
                  Login
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EmpresaJaCadastrada;
