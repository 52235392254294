import {
  Button,
  ButtonGroup,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState, useContext } from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';

function ConselhoMedico(prop) {
  const disclosure = prop.disclosure;
  const [sair, setSair] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { menuDisclosure } = useContext(AccountContext);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent height="350px" width="350px" borderRadius="1.8em">
          <ModalBody pt="3.5em">
            <VStack>
              <Text fontSize="1em" lineHeight="1em" color="#585858" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Se o gestor da clínica também for médico, preencha o campo do CRM.
              </Text>
              <Text fontSize="1em" lineHeight="1em" color="#585858" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                O preenchimento é necessário para que o médico possa solicitar exames e prescrever medicamentos
              </Text>
              <ButtonGroup flexDirection={'column'} w="70%" pt="15%">
                <Button
                  backgroundColor="#5AB2AD"
                  colorScheme="black"
                  h="3em"
                  type="submit"
                  borderRadius="0.8em"
                  onClick={() => {
                    disclosure.onClose();
                  }}
                >
                  Voltar
                </Button>
                <Button
                  colorScheme="teal"
                  variant="link"
                  pt="10%"
                  textDecoration={'underline'}
                  onClick={() =>
                    navigate('/repassefinanceiro', {
                      state: {
                        ...state,
                        ...prop?.paciente_data,
                      },
                    })
                  }
                >
                  Não é um médico
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConselhoMedico;
