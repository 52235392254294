import {
  Center,
  Heading,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Button,
  ModalFooter,
  Text,
  VStack,
  ButtonGroup,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AccountContext } from '../../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';

function ModalAvisoBird(props) {
  const disclosure = props.disclosure;
  const { setUser, user } = useContext(AccountContext);
  const navigate = useNavigate();
  return (
    <>
      <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="red" mt="5" size="md">
                Aviso
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="1.2em">
              O não preenchimento do certificado digital não permitirá prescrever receitas
            </chakra.h4>
            <VStack>
              <ButtonGroup flexDirection={'row'} pt="15%">
                <Button
                  backgroundColor="#4D9140"
                  type="submit"
                  onClick={() => {
                    disclosure.onClose();
                  }}
                  w="60%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Voltar
                </Button>

                <Button
                  backgroundColor="red"
                  type="submit"
                  onClick={() => {
                    setUser((prevState) => ({ ...prevState, ignoraBird: true }));
                    navigate('/dashboard');
                  }}
                  w="75%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Continuar
                </Button>
              </ButtonGroup>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalAvisoBird;
