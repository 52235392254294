import { Button, ButtonGroup, HStack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import { AccountContext } from '../AccountContext';
import TextField from '../Utils/Inputs/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import EmpresaJaCadastrada from '../Utils/Modal/Dashboard/EmpresaJaCadastrada';

function ValidacaoCNPJ() {
  const { setUser, setPage } = useContext(AccountContext);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [userData, setUserData] = useState({});
  const { setEmpresa, setEmpresaJaExiste } = useState({});
  const navigate = useNavigate();
  const empresaJaCadastradaConst = useDisclosure();
  setPage('Cadastro');
  function validateCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Eliminate known invalid CNPJs
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Validate DVs
    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0))
      return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1))
      return false;

    return true;

  }
  return (
    <>
      <NoUserHeader />
      <EmpresaJaCadastrada disclosure={empresaJaCadastradaConst} />
      <VStack w="100%">
        {' '}
        <Formik
          initialValues={{ cnpj: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            cnpj: Yup.string()
              .required('Campo obrigatório')
              .trim()
              .matches(
                /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
                'CNPJ inválido. Deve seguir o formato XX.XXX.XXX/XXXX-XX'
              )
              .test('isValidCnpj', 'CNPJ inválido', (value) => validateCNPJ(value)),
          })}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            let cnpj = onlyNumbers(vals.cnpj);
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getClientByCNPJ/${cnpj}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .catch((err) => {
                return err;
              })
              .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                }
                return res.json();
              })
              .then((response) => {
                if (response.message === 'sucesso') {
                  if (response.data.empresa_id !== null && response.data.empresa_id > 0 && response.data.empresa_id !== undefined) {
                    empresaJaCadastradaConst.onOpen();
                    setEmpresaJaExiste(true);
                  }
                } else {
                  navigate('/cadastro', {
                    state: {
                      cnpj: vals.cnpj,
                    },
                  });
                }
              });
          }}
        >
          <VStack as={Form} w="100%">
            <VStack w="80%" textAlign={'center'}>
              <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                Digite o CNPJ da empresa
              </Text>

              <VStack w="100%" pt="6em">
                <TextField
                  name="cnpj"
                  label="CNPJ*"
                  placeholder="Digite o CNPJ da empresa"
                  mask="99.999.999/9999-99"
                  textAlign="center"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="tel"
                  h="3.8em"
                  fontWeight="bold"
                  borderRadius="1em"
                  autoComplete="off"
                />
              </VStack>

              <ButtonGroup flexDirection={'column'} w="70%" pt={{ md: '22em', sm: '75%' }}>
                <Button backgroundColor="#95F3EE" type="submit" color="#5AB2AD" h="3em" borderRadius="2em">
                  Seguir
                </Button>
              </ButtonGroup>
            </VStack>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default ValidacaoCNPJ;
