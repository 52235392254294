import React, { useContext, useEffect, useState } from 'react';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import { AccountContext } from '../../AccountContext';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  HStack,
  Heading,
  SelectField,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  chakra,
  useToast,
} from '@chakra-ui/react';
import Loading from '../../Utils/Loading';
import { MdShare } from 'react-icons/md';
import { format } from 'date-fns';

function ListaHistoricos() {
  const { user, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  setPage('Histórico');
  const [medicos, setMedicos] = useState([]);
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [receitas, setReceitas] = useState([]);
  const [atestados, setAtestados] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingSolicitacoes, setLoadingSolicitacoes] = useState(false);
  const [loadingReceitas, setLoadingReceitas] = useState(false);
  const [loadingAtestados, setLoadingAtestados] = useState(false);
  const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');
  const [counter, setCounter] = useState(30);
  const [showText, setShowText] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [ultimaSelecao, setUltimaSelecao] = useState('');
  const toast = useToast();
  function fetchMedicos() {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectOperatorCompany/${user.empresa_id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          return;
        }
        return response.json();
      })
      .then((result) => {
        setMedicos(result.data);
      });
  }

  function fetchSolicitacoes(userId) {
    console.log('solicitacoes');
    console.log(userId);
    setLoadingSolicitacoes(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getEstimateWorked/${user.perfil_id === 4 ? user.username : userId}/${user.empresa_id}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .catch((err) => {
        console.log(err);
        return;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          return;
        }
        return response.json();
      })
      .then(async (result) => {
        setLoadingSolicitacoes(false);
        setIsLoading(false);
        setSolicitacoes(result?.data?.data);
      });
  }

  function fetchReceitas(userId) {
    setLoadingReceitas(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getHistoricoReceitas/${user.perfil_id === 4 ? user.username : userId}/${user.empresa_id}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .catch((err) => {
        console.log(err);
        return;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          return;
        }
        return response.json();
      })
      .then(async (result) => {
        setIsLoading(false);
        setLoadingReceitas(false);
        console.log(result?.data?.data);
        setReceitas(result?.data?.data);
      });
  }

  function fetchAtestados(userId) {
    setLoadingAtestados(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getHistoricoAtestados/${user.perfil_id === 4 ? user.username : userId}/${
        user.empresa_id
      }`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .catch((err) => {
        console.log(err);
        return;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          return;
        }
        return response.json();
      })
      .then(async (result) => {
        console.log(result);
        setIsLoading(false);
        setLoadingAtestados(false);
        setAtestados(result?.data?.data);
      });
  }

  useEffect(() => {
    fetchMedicos();
    fetchSolicitacoes();
    fetchReceitas();
    fetchAtestados();
  }, []);

  function callToast() {
    toast({
      title: 'Importante',
      description: `A empresa em que você está tentando realizar solicitação está com restrição de acesso devido problemas no cadastro da conta bancaria. ${
        user.perfil_id === 10
          ? 'Atualize os dados bancários para voltar a utilizar o serviço.'
          : 'Entre em contato com o responsável para atualizar os dados bancários para e voltar a utilizar o serviço'
      } `,
      status: 'error',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  function showToast() {
    toast({
      position: 'bottom',

      duration: 30000,
      isClosable: true,
      render: () => (
        <Alert
          status="error"
          variant="solid"
          flexDirection="column"
          onClick={() => {
            console.log('click');
          }}
          rounded="md"
        >
          <HStack w="100%">
            <AlertIcon />
            <AlertTitle w="80%" align="center">
              Importante
            </AlertTitle>
            <CloseButton alignSelf="flex-end" position="relative" right={-1} top={-1} onClick={() => toast.closeAll()} />
          </HStack>
          <AlertDescription
            maxWidth="sm"
            textAlign="justify"
            onClick={() => {
              toast.closeAll();
              navigate('/validacaobird');
            }}
          >
            Apenas usuários com certificado digital podem prescrever receitas. Clique aqui para validar seu certificado digital.
          </AlertDescription>
        </Alert>
      ),
      containerStyle: {
        paddingBottom: '100px',
      },
    });
  }

  const SelectClient = () => {
    if (!medicos) {
      return;
    }
    const arrayNomesMedicos = medicos?.map((medico) => medico.nome);

    return (
      <SelectField
        name="medicos"
        label="Médicos:"
        placeholder="Médicos"
        w="90%"
        ml={'1em'}
        variant="outline"
        textAlign="left"
        borderColor="red"
        height={'2em'}
        border={'1px solid black'}
        borderRadius="10px"
        fontWeight="bold"
        value={ultimaSelecao}
        onChange={(e) => {
          const nomeMedicoSelecionado = e.target.value;
          setUltimaSelecao(nomeMedicoSelecionado); // Atualiza a última seleção
          const medico = medicos?.find((m) => m.nome === nomeMedicoSelecionado);
          if (medico) {
            fetchSolicitacoes(medico.cpf);
            fetchReceitas(medico.cpf);
            fetchAtestados(medico.cpf);
          }
        }}
      >
        {arrayNomesMedicos?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </SelectField>
    );
  };
  function myToLowerCase(text) {
    if (typeof text === 'string') {
      return text.toLocaleLowerCase();
    } else {
      return text;
    }
  }
  const Solicitacoes = (props) => {
    return solicitacoes && solicitacoes?.length > 0 ? (
      <>
        {solicitacoes?.map((solicitacao) => (
          <Flex
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={'orange'}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
          >
            <VStack alignItems="right" spacing={2.5}>
              <Heading fontSize="1em">AGENDAMENTO DE {solicitacao?.itens?.length > 0 ? 'EXAMES' : 'CONSULTA'}</Heading>
              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Solicitante: </chakra.span>
                {solicitacao.solicitante}
              </chakra.p>
              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Data: </chakra.span>
                {formatDate(solicitacao.data_cadastro)}
              </chakra.p>
              {/* <chakra.p fontSize="0.8em">
                                <chakra.span fontWeight="bold">  Status: </chakra.span>
                                {
                                    solicitacao.orcamento_recusado === true ? 'Recusado' :
                                        solicitacao.orcamento_ativo === true && solicitacao.orcamento_recusado === false ? 'Orçamento pendente' :
                                            solicitacao.orcamento_ativo === true && solicitacao.orcamento_recusado === false ? 'Orçamento ativo' :
                                                'Orçamento pago'
                                }
                            </chakra.p> */}

              <chakra.p fontSize="0.8em">
                {solicitacao?.itens?.length > 0 ? <chakra.span fontWeight="bold"> Procedimentos: </chakra.span> : <></>}
                {solicitacao?.itens?.map((solicitacao_item) => (
                  <chakra.li>{solicitacao_item.nome}</chakra.li>
                ))}
              </chakra.p>
              <chakra.p fontSize="0.8em">
                <Text casing="capitalize">
                  <chakra.span fontWeight="bold">Paciente: </chakra.span>
                  {myToLowerCase(solicitacao.paciente_nome)}
                </Text>
              </chakra.p>

              {/*  <chakra.p fontSize="0.8em">
                                <Text casing="capitalize">
                                    <chakra.span fontWeight="bold">Local: </chakra.span>
                                    {solicitacao?.empresa.toUpperCase()} - {myToLowerCase(solicitacao.endereco)}, {myToLowerCase(solicitacao.numero)} - {myToLowerCase(solicitacao.cidade)}
                                </Text>
                                </chakra.p> */}
              <Button
                mt="4"
                borderRadius="10px"
                background={'#EB8B2A'}
                color="white"
                size="sm"
                maxwidth="30em"
                height="10"
                onClick={() => {
                  navigate('/medicos/historico/detalhes', {
                    state: {
                      atendimento: solicitacao,
                    },
                  });
                }}
              >
                ↓ Detalhes
              </Button>
            </VStack>
          </Flex>
        ))}
      </>
    ) : loadingSolicitacoes ? (
      <>
        <Loading />
      </>
    ) : !ultimaSelecao ? (
      <>
        <Heading fontSize="1em">Selecione um médico para visualizar o histórico de solicitação de exames e receitas</Heading>
      </>
    ) : (
      <>
        <Heading fontSize="1em">Não há histórico de solicitações para o médico selecionado</Heading>
      </>
    );
  };

  const AreaLivre = (props) => {
    const dadosFiltrados = atestados && atestados.length > 0 ? atestados.filter((item) => item.relatorio_livre === true) : [];

    // Ordena os dados filtrados pela data_cadastro mais recente para a mais antiga
    dadosFiltrados.sort((a, b) => new Date(b.data_cadastro) - new Date(a.data_cadastro));

    return dadosFiltrados && dadosFiltrados.length > 0 ? (
      <>
        {dadosFiltrados.map((atestado, index) => (
          <Flex
            key={index}
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={'teal'}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
            wordBreak="break-word"
          >
            <VStack alignItems="right" spacing={2.5}>
              <Heading fontSize="1em">{atestado?.paciente?.toUpperCase()}</Heading>
              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Médico: </chakra.span>
                {atestado?.medico}
              </chakra.p>

              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Texto: </chakra.span>
                {atestado.texto}
              </chakra.p>

              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Data: </chakra.span>
                {formatDate(atestado?.data_cadastro)}
              </chakra.p>
            </VStack>
          </Flex>
        ))}
      </>
    ) : loadingAtestados ? (
      <Loading />
    ) : (
      <Heading fontSize="1em">Não há histórico de atestados para o médico selecionado</Heading>
    );
  };

  const Atestados = (props) => {
    const dadosFiltrados = atestados && atestados.length > 0 ? atestados?.filter((item) => item.relatorio_livre === false) : [];
    dadosFiltrados.sort((a, b) => new Date(b.data_cadastro) - new Date(a.data_cadastro));
    return dadosFiltrados && dadosFiltrados.length > 0 ? (
      <>
        {dadosFiltrados.map((atestado, index) => (
          <Flex
            key={index}
            minW="210px"
            maxW="340px"
            mx="auto"
            alignItems="center"
            overflow="hidden"
            border="1px solid"
            borderColor={'teal'}
            borderRadius="10px"
            padding="1em"
            marginTop={5}
            px={5}
            pt={5}
            wordBreak="break-word"
          >
            <VStack alignItems="right" spacing={2.5}>
              <Heading fontSize="1em">{atestado?.paciente?.toUpperCase()}</Heading>
              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Médico: </chakra.span>
                {atestado?.medico}
              </chakra.p>

              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Texto: </chakra.span>
                {atestado.texto}
              </chakra.p>

              <chakra.p fontSize="0.8em">
                <chakra.span fontWeight="bold"> Data: </chakra.span>
                {formatDate(atestado?.data_cadastro)}
              </chakra.p>
            </VStack>
          </Flex>
        ))}
      </>
    ) : loadingAtestados ? (
      <Loading />
    ) : (
      <Heading fontSize="1em">Não há histórico de atestados para o médico selecionado</Heading>
    );
  };

  function reenviarReceitas(dados) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/reSendPrescricao`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
      },
      body: JSON.stringify({ dados: dados, assinatura: user.tokenBird }),
    }).then(async (response) => {
        if (response.status === 200) {
          fetchReceitas()
          toast({
            title: 'Importante',
            description: `Sucesso ao reenviar a prescrição do paciente.`,
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom',
            colorScheme: 'green',
            containerStyle: {
              paddingTop: '50px',
            },
          });
        } else {
          toast({
            title: 'Importante',
            description: `Houve um erro ao tentar reenviar a prescrição do paciente`,
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom',
            colorScheme: 'red',
            containerStyle: {
              paddingTop: '50px',
            },
          });
        }
      });
  }

  const contar = () => {
    let aux = 15;
    var countdown = setInterval(function () {
      aux--;
      setCounter(aux);
      if (aux === 0) {
        clearInterval(countdown);
        setShowText(false);
        setDisableButton((disableButton) => false);
        setCounter(30);
      }
    }, 1000);
  };

  const Receituarios = (props) => {
    return receitas && receitas?.length > 0 ? (
      <>
        {receitas?.map((receita, index) => {
          const drogaMatch = receita?.receita?.match(/Droga:(.*?)<\/p>/g);
          const apresentacaoMatch = receita?.receita?.match(/Apresentação:(.*?)<\/p>/g);
          const concentracaoMatch = receita?.receita?.match(/Concentração:(.*?)<\/p>/g);
          const posologiaMatch = receita?.receita?.match(/Posologia:(.*?)<\/p>/g);

          const droga = drogaMatch ? drogaMatch.map((match) => match.replace(/<p>|<\/p>/g, '').trim()) : [];
          const apresentacao = apresentacaoMatch ? apresentacaoMatch.map((match) => match.replace(/<p>|<\/p>/g, '').trim()) : [];
          const concentracao = concentracaoMatch ? concentracaoMatch.map((match) => match.replace(/<p>|<\/p>/g, '').trim()) : [];
          const posologia = posologiaMatch ? posologiaMatch.map((match) => match.replace(/<p>|<\/p>/g, '').trim()) : [];

          const receitaElements = [];
          for (let i = 0; i < droga.length; i++) {
            receitaElements.push(<chakra.li key={i}>{droga[i]}</chakra.li>);
            if (apresentacao[i]) receitaElements.push(<chakra.li key={i + 'a'}>{apresentacao[i]}</chakra.li>);
            if (concentracao[i]) receitaElements.push(<chakra.li key={i + 'b'}>{concentracao[i]}</chakra.li>);
            receitaElements.push(<chakra.li key={i + 'c'}>{posologia[i]}</chakra.li>);
            receitaElements.push(<chakra.p mb={'1em'}> </chakra.p>);
          }
          return (
            <VStack>
              <Flex
                key={index}
                minW="210px"
                maxW="340px"
                mx="auto"
                alignItems="center"
                overflow="hidden"
                border="1px solid"
                borderColor={'teal'}
                borderRadius="10px"
                padding="1em"
                marginTop={5}
                px={5}
                pt={5}
                wordBreak="break-word"
              >
                <VStack alignItems="right" spacing={2.5}>
                  <Heading fontSize="1em">{receita?.paciente?.toUpperCase()}</Heading>
                  <chakra.p fontSize="0.8em">
                    <chakra.span fontWeight="bold"> Médico: </chakra.span>
                    {receita?.medico}
                  </chakra.p>
                  <chakra.p fontSize="0.8em">
                    <chakra.span fontWeight="bold"> Tipo de receita: </chakra.span>
                    {receita?.especial === true ? 'Especial' : 'Comum'}
                  </chakra.p>
                  <chakra.p fontSize="0.8em">
                    <chakra.span fontWeight="bold"> Data: </chakra.span>
                    {formatDate(receita?.data_cadastro)}
                  </chakra.p>

                  <chakra.p fontSize="0.8em">
                    <chakra.span fontWeight="bold"> Receita: </chakra.span>
                    {receitaElements}
                  </chakra.p>
                  {receita.operador_id === user.operador_id ? (
                    <>
                      <Button
                        mt="4"
                        borderRadius="10px"
                        background={'#EB8B2A'}
                        color="white"
                        size="sm"
                        maxwidth="30em"
                        height="10"
                        onClick={() => {
                          if (user.empresa_valida !== true) {
                            callToast();
                            return;
                          } else if (user.tokenBird === null || !user.crm) {
                            showToast();
                            return;
                          }
                          setDisableButton(true);
                          reenviarReceitas(receita);
                          setShowText(true);
                          contar();
                        }}
                        disabled={disableButton}
                        isLoading={disableButton}
                        loadingText={'Aguarde' + ' ' + counter + ' ' + 'segundos'}
                        opacity={disableButton ? 1 : 1}
                        pointerEvents={disableButton ? 'none' : 'auto'}
                      >
                        Reenviar prescrição
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </VStack>
              </Flex>
            </VStack>
          );
        })}
      </>
    ) : loadingReceitas ? (
      <>
        <Loading />
      </>
    ) : (
      <>
        <Heading fontSize="1em">Não há histórico de receitas para o médico selecionado</Heading>
      </>
    );
  };

  return (
    <>
      <NoUserHeader />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          {user.perfil_id !== 4 ? <SelectClient pb="5" /> : ''}
          <VStack w="100%" align="center">
            <Tabs px={{ base: 2, sm: 1 }}>
              <TabList w="100%" maxW={'13.5em'}>
                <Tab w="50%">Solicitações</Tab>
                <Tab w="40%">Receitas</Tab>
                <Tab w="40%">Atestados</Tab>
                <Tab w="50%">Área Livre</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Solicitacoes guia="solicitacoes" />
                </TabPanel>
                <TabPanel>
                  <Receituarios guia="receituarios" />
                </TabPanel>
                <TabPanel>
                  <Atestados guia="atestados" />
                </TabPanel>

                <TabPanel>
                  <AreaLivre guia="area-livre" />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </>
      )}
    </>
  );
}

export default ListaHistoricos;
