import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { React, useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import TextField from '../../Utils/Inputs/TextField';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import Loading from '../../Utils/Loading';
import ExcluirConta from '../../Utils/Modal/Cadastro/ExcluirConta';
function EditarDadosClinica() {
  const { setUser, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const [empresa, setEmpresa] = useState(null);
  const [change_tel, setChangeTel] = useState(false);
  const excluirContaDesclousure = useDisclosure()
  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');
  setPage('Editar Cadastro');
  function showToast() {
    toast({
      title: '',
      position: 'bottom',
      description: 'Conta excluida com sucesso. Em caso de dúvidas contato suporte através do número 0800 280 2133',
      status: 'success',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingBottom: '50px',
      },
    });
  }
  const deleteAccount = async (username) => {
    let response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/delete_client/${username}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    if (postsData.message === 'sucesso') {
      showToast();
      await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/logout`, {
        method: 'GET',
        credentials: 'include',
      }).then(() => {
        navigate('/');
      });
    } else {
      console.log(postsData);
    }
  };
  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectEmpresarById/${user.empresa_id}`;
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const postsData = await response.json();
      setEmpresa(postsData.data);
    } catch (error) { }
  }
  useEffect(() => {
    fetchData(url);
  }, []);

  const [telefone_clinica, setTelefone] = useState('');
  const [telefone, setValue] = useState('');
  const [renderFormik, setRenderFormik] = useState(false);

  const applyMask = (telefone_clinica) => {
    const cleanedValue = telefone_clinica.replace(/\D/g, '');
    let maskedValue = '';

    if (cleanedValue.startsWith('0800')) {
      maskedValue = cleanedValue.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (cleanedValue.length === 8) {
      maskedValue = cleanedValue.replace(/(\d{4})(\d{3})(\d{1,4})$/, '$1-$2-$3');
    } else if (cleanedValue.length === 11) {
      maskedValue = cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (cleanedValue.length >= 10) {
      maskedValue = cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else {
      maskedValue = cleanedValue;
    }
    setTelefone(maskedValue);
    return maskedValue;
  };

  useEffect(() => {
    if (empresa && empresa[0]?.telefone) {
      const telefoneFormatado = applyMask(empresa[0]?.telefone);
      setTelefone(telefoneFormatado);
      setRenderFormik(true);
    }
  }, [empresa]);

  const FormularioEditar = ({ formikProps }) => {
    const { setFieldValue, values, isSubmitting, getFieldMeta } = formikProps;

    function applyMask(unmaskedValue) {
      async function applyMaskField() {
        try {
          if (unmaskedValue) {
            let cleanedValue = unmaskedValue.replace(/\D/g, '');
            let maskedValue = '';
            if (cleanedValue.startsWith('0800')) {
              maskedValue = cleanedValue.replace(/(\d{4})(\d{3})(\d{4})/, '$1-$2-$3');
            } else if (cleanedValue.length === 8) {
              maskedValue = cleanedValue.replace(/(\d{4})(\d{4})/, '$1-$2');
            } else if (cleanedValue.length === 10) {
              maskedValue = cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
            } else {
              maskedValue = cleanedValue;
            }
            console.log(maskedValue);
            setFieldValue('telefone_clinica', maskedValue);
          }
          return '';
        } catch (error) {
          setError('Não foi possivel consultar seu cep, verifique o cep ou preencha as informações manualmente');
        }
      }
      applyMaskField();
    }

    const CampoTelefone = () => {
      return (
        <>
          <TextField
            name="telefone_clinica"
            label="Telefone*"
            textAlign="left"
            fontSize="0.7em"
            color="#939598"
            backgroundColor="#EFEFEF"
            maskChar={null}
            type="tel"
            h="3.8em"
            fontWeight="bold"
            borderRadius="-0.5em"
            autoComplete="off"
            onChange={(e) => {
              setFieldValue('telefone_clinica', e.target.value);
              if (e.currentTarget.value.length >= 10) applyMask(e.currentTarget.value);
            }}
            maxLength={13}
          />
        </>
      );
    };

    return <>{CampoTelefone()}</>;
  };

  return (
    <>
      <NoUserHeader />
      <ExcluirConta disclosure={excluirContaDesclousure} />
      {empresa ? (
        <VStack w="100%">
          {' '}
          {renderFormik && (
            <Formik
              initialValues={{
                razao_social: empresa[0] && empresa[0].razao_social ? empresa[0].razao_social : '',
                nome_fantasia: empresa[0] && empresa[0].nome ? empresa[0].nome : '',
                cnpj: empresa[0] && empresa[0].cnpj ? empresa[0].cnpj : '',
                data_abertura: empresa[0] && empresa[0].data_abertura ? empresa[0].data_abertura.split('T')[0].split('-').reverse().join('/') : '',
                telefone_clinica: telefone_clinica,
                whatsapp_clinica: empresa[0] && empresa[0].whatsapp ? empresa[0].whatsapp : '',
              }}
              onSubmit={(values, actions) => {
                const vals = { ...values };
                navigate('/medicos/editarenderecoclinica', {
                  state: {
                    ...empresa,
                    razao_social: vals.razao_social,
                    nome_fantasia: vals.nome_fantasia,
                    cnpj: onlyNumbers(vals.cnpj),
                    data_abertura: vals.data_abertura.split('/').reverse().join('-'),
                    telefone_clinica: onlyNumbers(vals.telefone_clinica),
                    whatsapp_clinica: onlyNumbers(vals.whatsapp_clinica),
                  },
                });
              }}
              validationSchema={Yup.object({
                razao_social: Yup.string()
                  .required('Razão social é obrigatória!')
                  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Razão social não deve conter caracteres especiais, exceto números'),

                nome_fantasia: Yup.string()
                  .required('Nome fantasia é obrigatório')
                  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Nome fantasia não deve conter caracteres especiais, exceto números'),

                telefone_clinica: Yup.string()
                  .required('Telefone é obrigatório')
                  .test('validacao-telefone', 'Telefone Inválido', function (value) {
                    if (!value) return false;

                    const cleanedValue = value.replace(/\D/g, '');

                    if (cleanedValue.startsWith('0800')) {
                      return true;
                    }

                    if (cleanedValue.startsWith('0')) {
                      return false;
                    }

                    if (/^(\d)\1+$/.test(cleanedValue)) {
                      return false;
                    }

                    return true;
                  }),

                whatsapp_clinica: Yup.string()
                  .required('Whatsapp é obrigatório')
                  .trim()
                  .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
                data_abertura: Yup.date()
                  .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
                  .required('Data de abertura é obrigatória!')
                  .typeError('Data inválida, digite uma data no formato DD/MM/AAAA')
                  .test('minDate', 'A data de abertura deve ser a partir de 1900', function (value) {
                    const minDate = new Date(1900, 0, 1);
                    return value >= minDate;
                  })
                  .test('noFutureDate', 'A data de abertura não pode ser uma data futura', function (value) {
                    const currentDate = new Date();
                    return value <= currentDate;
                  }),
              })}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {(props) => {
                const { setFieldValue } = props;
                console.log(props.values)
                return (
                  <VStack as={Form} w="100%">
                    <VStack w="90%" textAlign={'center'}>
                      <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                        Aqui você irá editar os dados da clínica
                      </Text>
                      <TextField
                        label="Razão social*"
                        name="razao_social"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        w="100%"
                        autoComplete="off"
                        maxLength={50}
                      />
                      <TextField
                        name="nome_fantasia"
                        label="Nome fantasia*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        autoComplete="off"
                        maxLength={50}
                      />

                      <HStack flexDirection={'row'} w="100%">
                        <TextField
                          name="cnpj"
                          label="CNPJ*"
                          placeholder="000.000.000/00-00"
                          mask="99.999.999/9999-99"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="tel"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                          autoComplete="off"
                        />

                        <TextField
                          name="data_abertura"
                          label="Data de abertura*"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="tel"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                          autoComplete="off"
                          mask="99/99/9999"
                        />
                      </HStack>

                      <VStack spacing={2} w="100%">
                        <HStack spacing={4} alignItems="center" w="100%">
                          <FormularioEditar formikProps={props} />
                          <TextField
                            name="whatsapp_clinica"
                            label="Whatsapp*"
                            textAlign="left"
                            fontSize="0.7em"
                            color="#939598"
                            backgroundColor="#EFEFEF"
                            maskChar={null}
                            type="tel"
                            h="3.8em"
                            fontWeight="bold"
                            borderRadius="-0.5em"
                            autoComplete="off"
                            mask="(99) 99999-9999"
                          />
                        </HStack>
                      </VStack>

                      <ButtonGroup flexDirection={'column'} w="70%" pt="20%">
                        <Button backgroundColor="#95F3EE" type="submit" color="#5AB2AD" h="3em" borderRadius="2em">
                          Seguir
                        </Button>
                        <Text
                          mt="10%"
                          mb="10%"
                          color="red"
                          onClick={() => {
                            console.log(user);
                            excluirContaDesclousure.onOpen()
                            //deleteAccount(user.username);
                          }}
                        >
                          Desejo excluir minha conta
                        </Text>
                      </ButtonGroup>
                    </VStack>
                  </VStack>
                );
              }}
            </Formik>
          )}
        </VStack>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
}

export default EditarDadosClinica;
