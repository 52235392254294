import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Flex,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { React, useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../../Utils/Inputs/TextField';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import Loading from '../../Utils/Loading';
import * as Yup from 'yup';

function EditarEnderecoClinica() {
  const { setUser, setPage, menuDisclosure } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [location, setLocation] = useState();
  setPage('Cadastro');
  const [localidades, setLocalidades] = useState({ municipios: [], estados: [] });
  const [cep_clinica, setCep] = useState();
  const [blockButton, setButtonBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  async function buscarCep(cep_clinica, setFieldValue) {
    const validate = onlyNumbers(cep_clinica);

    try {
      if (validate.length === 8) {
        const response = await fetch(`https://viacep.com.br/ws/${validate}/json/`);
        if (!response.ok) {
          setButtonBlock(true);
          console.error('Erro ao consultar o ViaCEP');
          return;
        }

        const result = await response.json();

        if (result.erro) {
          setLoading(false);
          setButtonBlock(true);
          callToast('Importante', 'CEP não encontrado.', 'error');
          setFieldValue('cep_clinica', '');
          return;
        }

        setButtonBlock(false);
        setLoading(false);
        setLocation({ ...result });
        setFieldValue('cep_clinica', cep_clinica);
        setFieldValue('rua_clinica', result.logradouro);
        setFieldValue('bairro_clinica', result.bairro);
        setFieldValue('uf_clinica', result.uf);
        setFieldValue('cidade_clinica', result.localidade);
        setFieldValue('complemento', result.complemento);
        setFieldValue('municipio_id', result.ibge);
      }
    } catch (error) {
      console.log(error);
      setButtonBlock(true);
      setLoading(false);
      callToast('Não foi possível consultar seu CEP, verifique o CEP informado.');
    }
  }

  async function validaUf(uf_clinica) {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/valida_uf/${uf_clinica}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const resposta = await response.json();

      if (resposta.message === 'existe') {
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      console.error('Error during validaUf:', error);
      return false;
    }
  }
  const normalizeCEP = (cep) => cep.replace(/\D/g, '');
  return (
    <>
      <NoUserHeader />

      {state[0] ? (
        <VStack>
          {' '}
          <Formik
            initialValues={{
              cep_clinica: state && state[0].cep ? state[0].cep : '',
              rua_clinica: state && state[0].logradouro ? state[0].logradouro : '',
              numero_clinica: state && state[0].numero ? state[0].numero : '',
              bairro_clinica: state && state[0].bairro ? state[0].bairro : '',
              uf_clinica: state && state[0].uf ? state[0].uf : '',
              cidade_clinica: state && state[0].cidade ? state[0].cidade : '',
              complemento_clinica: state && state[0].complemento ? state[0].complemento : '',
            }}
            validateOnChange={true}
            validateOnBlur={false}
            validationSchema={Yup.object({
              cep_clinica: Yup.string()
                .required('CEP é obrigatório!')
                .test('validacao-cep', 'CEP inválido', (value) => {
                  const cep = normalizeCEP(value);
                  const cepRegex = /^[0-9]{8}$/;
                  if (!cepRegex.test(cep)) {
                    return false;
                  }
                  return true;
                }),
              rua_clinica: Yup.string()
                .required('Rua é obrigatório!')
                .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Rua não deve conter caracteres especiais, exceto números'),
              bairro_clinica: Yup.string()
                .required('Bairro é obrigatório!')
                .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'Bairro não deve conter caracteres especiais, exceto números'),
              uf_clinica: Yup.string()
                .required('Campo obrigatório')
                .matches(/^[^\d]+$/, 'Escreva a UF sem acentos, números ou caracteres especiais')
                .max(2, 'A UF não pode conter mais de 2 caracteres')
                .test('validate-uf', 'UF inválida', async function (value) {
                  if (value) {
                    const isValid = await validaUf(value);
                    return isValid;
                  }
                  return true;
                }),
              cidade_clinica: Yup.string()
                .matches(/^[a-zA-ZÀ-ÖØ-öø-ÿ\s']*$/, {
                  message: 'Somente letras, espaços e acentos são permitidos na cidade',
                })
                .required('Campo obrigatório'),
            })}
            onSubmit={(values, actions) => {
              const vals = { ...values };
              fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/updateCompanyLocation`, {
                method: 'POST',
                credentials: 'include',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                  ...state,
                  cep_clinica: vals.cep_clinica,
                  rua_clinica: vals.rua_clinica,
                  numero_clinica: vals.numero_clinica,
                  bairro_clinica: vals.bairro_clinica,
                  uf_clinica: vals.uf_clinica,
                  cidade_clinica: vals.cidade_clinica,
                  complemento_clinica: vals.complemento_clinica,
                  empresa_id: state[0].empresa_id,
                  municipio_id: location && location.ibge ? location.ibge : state[0].codigo_ibge,
                }),
              })
                .catch((err) => {
                  return err;
                })
                .then((response) => {
                  if (!response || !response.ok || response.status >= 400) {
                  }
                  return response.json();
                })
                .then((result) => {
                  if (!result) return;
                  if (result.message === 'sucesso') {
                    setUser((prevState) => ({
                      ...prevState,
                      empresa: `${state.nome_fantasia}`,
                      empresa_endereco: `${vals.rua_clinica}, ${vals.numero_clinica} - ${vals.bairro_clinica} - ${vals.uf_clinica}`,
                    }));
                    navigate('/alerts/sucesso/default');
                  }
                });
            }}
          >
            {(props) => {
              const { setFieldValue } = props;
              return (
                <VStack as={Form} w="100%">
                  <VStack w="90%" textAlign={'center'}>
                    <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                      Cadastre o endereço completo da clínica.
                    </Text>

                    <TextField
                      label="CEP*"
                      name="cep_clinica"
                      placeholder=""
                      mask="99999-999"
                      textAlign="left"
                      fontSize="0.7em"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      maskChar={null}
                      type="tel"
                      h="3.8em"
                      fontWeight="bold"
                      borderRadius="-0.5em"
                      w="100%"
                      onKeyUp={(e) => {
                        buscarCep(e.target.value, setFieldValue);
                      }}
                      maxLength={9}
                    />

                    <HStack w="100%">
                      <TextField
                        name="rua_clinica"
                        label="Rua*"
                        placeholder=""
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={100}
                      />
                    </HStack>

                    <HStack w="100%">
                      <TextField
                        name="numero_clinica"
                        label="N°"
                        placeholder=""
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="tel"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={10}
                      />

                      <TextField
                        name="bairro_clinica"
                        label="Bairro*"
                        textAlign="left"
                        fontSize="0.7em"
                        placeholder=""
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={40}
                      />
                    </HStack>

                    <HStack w="100%">
                      <TextField
                        name="uf_clinica"
                        label="UF*"
                        textAlign="left"
                        fontSize="0.7em"
                        placeholder=""
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={2}
                      />

                      <TextField
                        name="cidade_clinica"
                        label="Cidade*"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        placeholder=""
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        maxLength={40}
                        fontWeight="bold"
                        borderRadius="-0.5em"
                      />
                    </HStack>

                    <HStack w="100%">
                      <TextField
                        name="complemento_clinica"
                        label="Complemento"
                        placeholder=""
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                      />
                    </HStack>

                    <ButtonGroup flexDirection={'column'} w="70%" pt="10%">
                      <Button backgroundColor={'#95F3EE'} color={'#5AB2AD'} h="3em" type="submit" borderRadius="2em" isDisabled={blockButton}>
                        {blockButton ? 'Preencha todos os campos' : 'Concluir'}
                      </Button>
                    </ButtonGroup>
                  </VStack>
                </VStack>
              );
            }}
          </Formik>
        </VStack>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
}
// }

export default EditarEnderecoClinica;
