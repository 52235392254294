import React, { useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader';

function EscolheProcedimento() {
  const { state } = useLocation();
  const { medico } = state;
  const dadosMedico = medico.dados;
  const horarios = medico.horarios;
  const [agendas, setAgendas] = useState([]);
  const [procedimento, setProcedimento] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const avaliacao = Math.round(dadosMedico.avaliacao_media);




  let procedimentos = [];
  let nomesProcedimentos = [];
  horarios.forEach((horario) => {
    horario.procedimentos.forEach((procedimento) => {
      if (nomesProcedimentos.includes(procedimento.nome) === false) {
        procedimentos.push(procedimento);
        nomesProcedimentos.push(procedimento.nome);
      }
    });
  });


  return (
    <>
      <DoctorHeader />

    </>
  );
}

export default EscolheProcedimento;
