import { useNavigate } from 'react-router';
import socketIOClient from 'socket.io-client';
import { useLocation } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

const { createContext, useState, useEffect } = require('react');

export const AccountContext = createContext();

const UserContext = ({ children }) => {
  const ENDPOINT = process.env.REACT_APP_BASE_URL_API_SERVER;
  const [socket, setSocket] = useState();
  let device = 'ios';
  let url = window.location.href;
  const location = useLocation();

  if (url.includes('android')) {
    device = 'android';
  }
  useEffect(() => {
    // setSocket(socketIOClient(ENDPOINT));
  }, [url]);

  useEffect(() => {
    // let url = window.location.href;
    // if (socket) {
    //   const schedulingFlow = url.includes('/atendimento/pedido') || url.includes('/pagamentos');
    //   if (schedulingFlow) !socket.connected && socket.connect();
    //   else socket.connected && socket.emit('unlockSchedule', true);
    // }
  }, [socket, location.pathname]);

  const menuDisclosure = useDisclosure();
  const [pic, setPic] = useState('1');
  const [page, setPage] = useState('Titulo da Pagina');
  const [user, setUser] = useState({
    loggedIn: null,
    username: null,
    device: null,
    nome: null,
    empresa: null,
    empresa_id: null,
    perfil: null,
    tokenBird: null,
    lastBirdDate: null,
    endereco: null,
    cart: null,
    cnpj: null,
    perfil_id: null,
    qntdeMedico: null,
    recipient_id: null,
    ignoraBird: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      credentials: 'include',
    })
      .catch((err) => {
        setUser((user) => {
          return { loggedIn: false, device: device };
        });
        return;
      })
      .then((r) => {
        if (!r || !r.ok || r.status >= 400) {
          setUser((user) => {
            return { loggedIn: false, device: device };
          });
          return;
        }
        return r.json();
      })
      .then((data) => {
        if (!data) {
          setUser((user) => {
            return { loggedIn: false, device: device };
          });
          return;
        }
        setUser({ ...data, device: device });

        if (user.loggedIn === true && !user.tokenBird || user.ignoraBird) {
          navigate('/validacaobird');
        } else {
          navigate('/dashboard');
        }
      });
  }, []);
  return <AccountContext.Provider value={{ user, setUser, page, setPage, pic, setPic, socket, menuDisclosure }}>{children}</AccountContext.Provider>;
};

export default UserContext;
