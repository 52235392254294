import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import Bird from '../../../../assets/images/bird.svg';
import { React, useContext, useState } from 'react';
import { AccountContext } from '../../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../../../Utils/Inputs/TextField';
import Medicos from '../../../../assets/images/medicos.png';
import ModalAvisoBird from '../../../Utils/Modal/Cadastro/ModalAvisoBird';
function ValidacaoBirdId() {
  const { setUser, user } = useContext(AccountContext);
  const [error, setError] = useState('Erro ao validar Token');
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const modalAvisoDisclousure = useDisclosure();
  const [loading, setIsLoading] = useState(false);
  function showToast() {
    toast({
      title: '',
      position: 'bottom',
      description: error,
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        mb: '20%',
      },
    });
  }

  return (
    <VStack>
      {' '}
      <ModalAvisoBird disclosure={modalAvisoDisclousure} />
      <Formik
        initialValues={{ cpf: '' }}
        onSubmit={(values) => {
          setIsLoading(true)
          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/bird/oauth/pwd_authorize`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cpf: user.cpf, medico_id: user.operador_id, otp: values.codigo }),
          }).then((res) => {
            res.json().then((result) => {
              if (!result.success) {
                setIsLoading(false)
                setError(result.message);
                showToast();
                return;
              }
              setIsLoading(false)
              setUser((prevState) => ({ ...prevState, tokenBird: result.data }));
              navigate('/dashboard');
            });
          });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <VStack as={Form} w="100%">
          <VStack w="90%" textAlign={'center'}>
            <Text fontSize="1.5em" mt={'5em'} lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#1E515B" noOfLines={3} maxWidth="85%">
              Digite abaixo o código do seu certificado digital BirdID.
            </Text>
            <TextField
              name="codigo"
              placeholder="Cód. Certificado"
              textAlign="center"
              fontSize="0.7em"
              color="black"
              backgroundColor="white"
              maskChar={null}
              type="tel"
              h="4em"
              shadow="md"
              fontWeight="bold"
              borderRadius="2em"
              w="80%"
              maxLength={6}
            />
            <ButtonGroup flexDirection={'column'} w="80%" pt="3%">
              <Button backgroundColor="#5AB2AD" color="white" h="3em" type="submit" borderRadius="2em" isLoading={loading}>
                Validar
              </Button>

              <img src={Bird} alt="bird integração" mt={'1em'} />
            </ButtonGroup>
            <Text fontSize="1em" lineHeight="1em" textAlign={'left'} color="#1E515B" noOfLines={3} maxWidth="85%">
              Não possui certificado digital? Acesse: www.birdid.com.br
            </Text>
            <Button
              colorScheme='black'
              variant='link'
              h="5em"
              w="15em"
              mt="0.8em"
              fontWeight="bold"
              borderRadius="2em"
              onClick={() => {
                modalAvisoDisclousure.onOpen();
              }}
              style={{ textDecoration: 'underline', color: 'black' }}
            >
              Não desejo preencher agora
            </Button>
          </VStack>
        </VStack>
      </Formik>
    </VStack>
  );
}

export default ValidacaoBirdId;
