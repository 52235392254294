import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  useDisclosure,
  InputRightElement,
  InputGroup,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import Logo from '../../assets/images/deo-med.svg';
import { React, useContext, useState } from 'react';
import { AccountContext } from '../AccountContext';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import Medicos from '../../assets/images/medicos.png';
import ModalPrimeiroLogin from '../Utils/Modal/ModalPrimeiroLogin';
import * as Yup from 'yup';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

function Login() {
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const { setUser } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [cpf, setCpf] = useState();
  const [cnpj, setCnpj] = useState();
  const toast = useToast();
  const disclosure = useDisclosure();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const handleClick = () => setIsPasswordHidden(!isPasswordHidden);
  const [showCadastro, setShowCadastro] = useState(true);
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function authLogin(vals) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(vals),
    })
      .catch((err) => {
        showToast();
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          showToast();
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        if (data.primeiro_login === false && data.perfil === 4) {
          setCpf(onlyNumbers(data.cpf));
          disclosure.onOpen();
        } else {
          setUser((prevState) => ({ ...prevState, ...data }));
          if (data.status) {
            setError(data.status);
          } else if (data.loggedIn) {
            navigate('/dashboard');
          }
        }
      });
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  function validaTamanhoAparelho() {
    if (window.innerWidth === 400 && window.innerHeight === 800) {
      return true;
    }
    return false
  }

  const tamanhoCelular = validaTamanhoAparelho();

  return (
    <VStack align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
      {' '}
      <ModalPrimeiroLogin disclosure={disclosure} cpf={cpf} cnpj={cnpj} />
      <Formik
        initialValues={{ cpf: '', cnpj: '', senha: '' }}
        validationSchema={Yup.object({
          username: Yup.string()
            .required('CPF obrigatório!')
            .trim()
            .matches('([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})', 'Cpf inválido'),
          password: Yup.string().required('Senha Obrigatória!').min(6, 'Senha inválida!'),
        })}
        onSubmit={(values, actions) => {
          const vals = {
            username: onlyNumbers(values.username),
            password: values.password,
          };

          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/initialLogin`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(vals),
          })
            .catch((err) => {
              showToast();
              return;
            })
            .then((res) => {
              if (!res || !res.ok || res.status >= 400) {
                showToast();
                return;
              }
              return res.json();
            })
            .then((data) => {
              if (!data) return;

              if (!data.loggedIn && data?.dados === 'Senha errada!') {
                callToast('Importante', 'A senha informada não é válida para o usuário informado', 'error');
                return;
              }

              if (!data.loggedIn && data?.dados?.length === 0) {
                callToast('Importante', 'O CPF informado não foi encontrado em nossa base', 'error');
                return;
              }

              if (!data.loggedIn && data?.dados?.length === 0) {
                callToast('Importante', 'A senha informada esta incorreta. Tente novamente', 'error');
                return;
              }

              if (data.loggedIn) {
                authLogin(data.dados);
                return;
              }
              const dados = data.dados;

              navigate('/select-login', {
                state: {
                  dados,
                  vals,
                },
              });
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <VStack as={Form} w="100%" align="center">
          <Heading mt="10%">
            <img src={Logo} alt="logo deovita" />
          </Heading>
          <Text as="p" color="red">
            {error}
          </Text>
          <VStack w={['90%', '80%']} align="flex-start">
            <label style={{ color: 'black', fontWeight: 'bold', marginBottom: '-0.5em' }}>CPF</label>
            <TextField
              name="username"
              placeholder="Digite aqui seu CPF"
              textAlign="center"
              fontSize="0.7em"
              color="black"
              backgroundColor="#FFFFFF"
              mask="999.999.999-99"
              maskChar={null}
              type="tel"
              borderRadius="0.6em"
              h="3.9em"
              fontWeight="bold"
              autoComplete="off"
              mb={'1em'}
              onFocus={() => tamanhoCelular ? setShowCadastro(false) : setShowCadastro(true)}
              onBlur={() => setShowCadastro(true)}
            />
            <label style={{ color: 'black', fontWeight: 'bold', marginBottom: '-0.5em' }}>Senha</label>
            <InputGroup>
              <TextField
                name="password"
                placeholder="Digite aqui sua senha"
                textAlign="center"
                fontSize="0.7em"
                color="black"
                backgroundColor="#FFFFFF"
                maskChar={null}
                borderRadius="0.6em"
                h="4.1em"
                fontWeight="bold"
                autoComplete="off"
                type={isPasswordHidden ? 'password' : 'text'}
                onFocus={() => {
                  setShowCadastro(true)
                }}
              />
              <InputRightElement width="2rem" height="3.6rem" pt="0.4rem" pr="1rem" size="4rem">
                <Button
                  borderRadius="20%"
                  colorScheme="twitter"
                  variant="outline"
                  pr="1em"
                  size="lg"
                  onClick={handleClick}
                  leftIcon={isPasswordHidden ? <ViewOffIcon /> : <ViewIcon />}
                ></Button>
              </InputRightElement>
            </InputGroup>
          </VStack>
          <HStack justify="space-between" w={['90%', '80%']} mt={['1em', '2em']}>
            <chakra.p
              color="#318681"
              paddingTop="1em"
              paddingBottom="1em"
              fontSize="0.9em"
              fontWeight={'bold'}
              onClick={() => {
                navigate('/recuperacao');
              }}
            >
              Esqueci minha senha
            </chakra.p>

            <ButtonGroup flexDirection={'column'} pt="3%" w={['40%', 'auto']}>
              <Button backgroundColor="#5AB2AD" color="#FFFFFF" h="3em" fontWeight={'bold'} type="submit" borderRadius="0.8em">
                Acessar
              </Button>
            </ButtonGroup>
          </HStack>
          {showCadastro && (
            <VStack
              w="100%"
              bottom="0"
              left="100%"
              transform="translateX(-100%)"
              p={4}
              zIndex="999"
              position="fixed"
              bg={'#5AB2AD'}
              color={'white'}
              borderTopRadius={'1.5em'}
            >
              <chakra.h1 marginBottom="-1em">Não possuo cadastro</chakra.h1>
              <chakra.h1 paddingTop="1.5%" fontSize={'1.3em'} fontWeight={'bold'} onClick={() => navigate('/CNPJ')} p={'1em'}>
                Cadastrar-me
              </chakra.h1>
            </VStack>
          )}
        </VStack>
      </Formik>
    </VStack>
  );
}

export default Login;
