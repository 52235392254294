import { useContext, useEffect, useState, useRef  } from 'react';
import {
  Flex,
  HStack,
  Box,
  chakra,
  Avatar,
  WrapItem,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Center,
  Heading,
  Stack,
  Button,
  ModalFooter,
  List,
  ButtonGroup
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader';
import { AccountContext } from '../../../AccountContext';
import Loading from '../../../Utils/Loading';
import ExameAction from '../../../Utils/Drawer/ExameAction';
import DotsLoading from '../../../DotsLoading/DotsLoading';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function Consulta() {
  const pic = getRandomInt(0, 999);
  const { user, setUser, setPage } = useContext(AccountContext);
  const [schedules, setSchedules] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [busyStack, setBusyStack] = useState(false);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fetchMore, setFetchMore] = useState(false);
  const modalDisclosure = useDisclosure();
  const actionDisclosure = useDisclosure();
  const scrollContainerRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false)

  setPage('Escolha o médico');

  useEffect(() => {
    (async () => {
      getSchedules(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule?page=${countPage}&limit=${limit}`);
    })();
  }, []);



  const handleScroll = async () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollContainerRef.current;
    if (scrollTop + clientHeight >= scrollHeight && !isFetching) {
      console.log("should call ");

      setIsFetching(true)


      try {


        // Await the result of getSchedules
        await getSchedules(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule?page=${countPage}&limit=${limit}`);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if needed
      }

      setIsFetching(false);
    }
  };



  async function getSchedules(url) {
    console.log('im fetching data', url)
    const response = await fetch(url);
    const data = await response.json();
    setSchedules(prev => [...prev, ...data.schedules]);
    setCountPage(prev => prev + 1);
    setLoading(false);
  }

  const useMedicos = schedules.map((medico, index) => {
    const arrName = medico.dados.nome.split(' ');
    const preposicoes = ['das', 'dos', 'des', 'da', 'do', 'de'];
    const nomeMedico = Array.isArray(arrName)
      ? arrName.filter((name, index) => index <= 1 && preposicoes.includes(name.toLowerCase()) === false).join(' ')
      : '';

    if (!medico.horarios) {
      medico.horarios = [
        {
          AVA_DATE: '0',
          procedimentos: [
            {
              id: 0,
              nome: '',
              valor: '0',
              convenio_id: 0,
              procedimento_particular: [
                {
                  particular_id: 0,
                  particular_valor: '0',
                },
              ],
            },
          ],
        },
      ];
    }

    const avaliacao = Math.round(medico.dados.avaliacao_media);
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    let titulo = '';
    let promocao = '';
    if (medico.dados.sexo === 'M') {
      titulo = 'Dr. ';
    } else if (medico.dados.sexo === 'F') {
      titulo = 'Dra. ';
    }

    return (
      <>
        <Flex
          id={index}
          minW="320px"
          maxW="320px"
          mx="auto"
          overflow="hidden"
          bg="#f0f0f0"
          borderRadius="20px"
          onClick={() => {
            setUser((prevState) => ({
              ...prevState,
              cart: {
                ...prevState.cart,
                consultas: [...(prevState.cart?.consultas ?? []), medico],
              },
            }));
            //modalDisclosure.onOpen()
          }}
        >
          <Box w={1 / 3} alignItems="center" justifyContent="center" bgSize="cover" align="center" display="flex" mt={-1.5}>
            <WrapItem ml="5px" mt="0.4em">
              <Avatar
                borderRadius="15px"
                id={medico.dados.medico_id}
                size="xl"
                name={medico.dados.nome}
                src={process.env.REACT_APP_URL_PROFILEPICS_OPERADOR + '/' + medico.dados.medico + '/foto_profissional.webp?' + pic}
              />{' '}
            </WrapItem>
          </Box>
          <Box w={2 / 3} p={{ base: 2, md: 4 }}>
            <Text mt={-1} casing="capitalize" fontSize="0.9em" fontWeight="bold" color="#585858">
              {titulo}
              {''}
              {nomeMedico.toLocaleLowerCase()}
            </Text>
            <chakra.p mt={-1} fontSize={medico?.dados?.especialidade?.length < 20 ? '0.6em' : '0.6em'} fontWeight={'0.8em'} color="#818181">
              {medico.dados.especialidade}
            </chakra.p>
            <HStack>
              <chakra.p fontSize="0.625em" color="#818181">
                {medico.dados.conselho && medico.dados.conselho} {medico.dados.conselho && medico.dados.rqe && ' '}{' '}
                {medico.dados.rqe && medico.dados.rqe}
              </chakra.p>
            </HStack>
            <HStack>
              <chakra.p fontSize="0.625em" align="justify-self">
                {medico.dados.empresa}
              </chakra.p>
            </HStack>
            <HStack>
              <chakra.p fontSize="0.625em" align="justify-self">
                {medico.dados.logradouro}, {medico.dados.numero} - {medico.dados.bairro} - {medico.dados.municipio}
              </chakra.p>
            </HStack>
            <HStack spacing={4} mt={0} display="flex" alignItems="center" fontSize="sm" pt={0}>
              <chakra.span mt={-0.5}>
                {avaliacao >= 1 ? <StarIcon color="#EB8B2A" fontSize="1em" mt={-0.3} /> : <StarIcon color="gray.500" fontSize="1em" mt={-0.3} />}

                <chakra.span fontSize="0.625em" color="#EB8B2A" fontWeight={600}>
                  {' '}
                  {avaliacao >= 5 ? '5' : avaliacao}
                </chakra.span>
              </chakra.span>
            </HStack>
            <chakra.p color="red.500">{promocao}</chakra.p>
          </Box>
        </Flex>

        <Flex
          minW="320px"
          maxW="320px"
          mx="auto"
          mb={2}
          bg="#ffffff"
          borderRadius="0 0 20px 20px"
          overflow="hidden"
          alignItems="center"
          justifyContent="space-between"
        ></Flex>
      </>
    );
  });

  return (
    <>
      <ExameAction type={'PRESCRICAO'} disclosure={actionDisclosure} />

      <Modal isOpen={modalDisclosure.isOpen} isCentered onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Deseja solicitar outra consulta?{' '}
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
              Antes de prosseguir, adicione outras consultas que deseja solicitar.
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <Button
                bgColor="teal"
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={() => {
                  modalDisclosure.onClose();
                }}
              >
                Adicionar mais procedimentos
              </Button>
              <Button
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  modalDisclosure.onClose();
                  actionDisclosure.onOpen();
                }}
              >
                Já selecionei todos
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <DoctorHeader />

      <List h={'70vh'} overflow={'scroll'} ref={scrollContainerRef} onScroll={handleScroll}>
        {loading ? <Loading /> : schedules && useMedicos}
        {isFetching ? <><DotsLoading /></>: <></>}
      </List>

      <ButtonGroup pt="5%" pb="0"  justifyContent={'center'} display={'flex'}>
        <Button
          colorScheme="teal"
          w="20em"
          h="3em"
          type="submit"
          borderRadius="2em"
          isDisabled={user.cart && user.cart.consultas && user.cart.consultas.length > 0 ? false : true}
          onClick={() => {
            modalDisclosure.onOpen();
          }}
        >
          Concluir
        </Button>
      </ButtonGroup>
    </>
  );
}

export default Consulta;
