import { Button, ButtonGroup, HStack, Text, VStack, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import { AccountContext } from '../AccountContext';
import TextField from '../Utils/Inputs/TextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { parse } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import OperadorJaCadastrado from '../Utils/Modal/Dashboard/OperadorJaCadastrado';
import validaCPF from '../Utils/Functions/validaCPF';

function ValidacaoCPF() {
  const { setUser, setPage } = useContext(AccountContext);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  const [userData, setUserData] = useState({});
  const { setOperador, setOperadorExiste } = useState({});
  const navigate = useNavigate();
  const OperadorJaCadastradoConst = useDisclosure();
  const [client, setDataClient] = useState({});
  const [cpf_gestor, setCpfGestor] = useState(0);
  const { state } = useLocation();
  setPage('Cadastro');
  return (
    <>
      <NoUserHeader />
      <OperadorJaCadastrado disclosure={OperadorJaCadastradoConst} dataClient={client} cpf_gestor={cpf_gestor} />
      <VStack w="100%">
        {' '}
        <Formik
          initialValues={{ cpf_gestor: '' }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            cpf_gestor: Yup.string()
              .required('CPF Obrigatório!')
              .trim()
              .test('valida_cpf', 'CPF inválido', (cpf_gestor) => validaCPF(cpf_gestor)),
          })}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            let cpf = onlyNumbers(vals.cpf_gestor);
            setCpfGestor(cpf)
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/getClientByCPF/${cpf}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .catch((err) => {
                return err;
              })
              .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                }
                return res.json();
              })
              .then((response) => {
                if (response.message === 'sucesso') {
                  if (response.data.operador_id !== null && response.data.operador_id > 0 && response.data.operador_id !== undefined) {
                    setDataClient(response);
                    OperadorJaCadastradoConst.onOpen();
                  } else {
                    navigate('/repassefinanceiro', {
                      state: {
                        cpf_gestor: cpf,
                        ...state,
                      },
                    });
                  }
                } else {
                  navigate('/gestordaclinica', {
                    state: {
                      cpf_gestor: cpf,
                      ...state,
                    },
                  });
                }
              });
          }}
        >
          <VStack as={Form} w="100%">
            <VStack w="80%" textAlign={'center'}>
              <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={1} maxWidth="100%">
                Digite o CPF do gestor da clínica
              </Text>

              <VStack w="100%" pt="6em">
                <TextField
                  name="cpf_gestor"
                  label="CPF*"
                  placeholder=""
                  mask="999.999.999-99"
                  textAlign="left"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="tel"
                  h="3.8em"
                  fontWeight="bold"
                  borderRadius="-0.5em"
                  autoComplete="off"
                />
              </VStack>

              <ButtonGroup flexDirection={'column'} w="70%" pt={{ md: '20em', sm: '78%' }}>
                <Button backgroundColor="#95F3EE" type="submit" color="#5AB2AD" h="3em" borderRadius="2em">
                  Seguir
                </Button>
              </ButtonGroup>
            </VStack>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default ValidacaoCPF;
