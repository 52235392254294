import { Button, ButtonGroup, Flex, Heading, Select, Text, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import Logo from '../../assets/images/deo-med.svg';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ModalPrimeiroLogin from '../Utils/Modal/ModalPrimeiroLogin';
import { AccountContext } from '../AccountContext';

function SelectEmpresa() {
    const state = useLocation();
    const [ultimaSelecao, setUltimaSelecao] = useState('');
    const navigate = useNavigate();
    const arrayCnpj = state?.state?.dados?.map((cnpj) => cnpj.cnpj);
    const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
    const toast = useToast();
    const [cpf, setCpf] = useState();
    const disclosure = useDisclosure();
    const { setUser } = useContext(AccountContext);
    const [error, setError] = useState(null);
    function showToast() {
        toast({
            title: '',
            position: 'top',
            description: 'Sistema em Manutenção',
            status: 'error',
            duration: 2000,
            isClosable: true,
            containerStyle: {
                paddingTop: '50px',
            },
        });
    }
    return (
        <Flex position="relative" direction="column" align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
            <ModalPrimeiroLogin disclosure={disclosure} cpf={cpf} cnpj={ultimaSelecao} />
            <Formik
                initialValues={{ cnpj: '' }}
                validationSchema={Yup.object({})}
                onSubmit={(values, actions) => {
                    const vals = {
                        cnpj: ultimaSelecao,
                        username: state.state.vals.username,
                        password: state.state.vals.password
                    }
                    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/login`, {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(vals),
                    })
                        .catch((err) => {
                            showToast();
                            return;
                        })
                        .then((res) => {
                            if (!res || !res.ok || res.status >= 400) {
                                showToast();
                                return;
                            }
                            return res.json();
                        })
                        .then((data) => {
                            if (!data) return;
                            if (data.primeiro_login === false && data.perfil === 4) {
                                setCpf(onlyNumbers(data.cpf));
                                disclosure.onOpen();
                            } else {
                                setUser((prevState) => ({ ...prevState, ...data }));
                                if (data.status) {
                                    setError(data.status);
                                } else if (data.loggedIn) {
                                    navigate('/dashboard');
                                }
                            }
                        });
                }}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <VStack as={Form} w="100%"  >
                    <IoArrowBackCircleOutline style={{ position: 'absolute', left: '1.5em', top: '3em' }} size="2rem" color="#5AB2AD" onClick={() => navigate(-1)} />
                    <Heading mt="10%">
                        <img src={Logo} alt="logo deovita" />
                    </Heading>
                    <label style={{ marginRight: '17em', color: 'black', fontWeight: 'bold', marginTop: '2em' }}>CNPJ</label>
                    <Select
                        name={'cnpj'}
                        placeholder="Selecione o CNPJ vinculado"
                        w="80%"
                        onChange={(e) => {
                            setUltimaSelecao(e.target.value)
                        }}
                        value={ultimaSelecao}
                        height={'3em'}
                        bg={'white'}
                        borderColor={'#5AB2AD'}
                    >
                        {arrayCnpj
                            ?.filter((value, index, self) => self.indexOf(value) === index)
                            .map((option, index) => (
                                <option key={index} value={option} style={{ color: 'black' }}>
                                    {option}
                                </option>
                            ))}
                    </Select>

                    <Text as="p" color="red">
                        {!ultimaSelecao ? '' : error}
                    </Text>
                    {!ultimaSelecao ? (<></>) : (<>
                        <VStack
                            w="87%"
                            align="left"
                            bottom="0"
                            left="50%"
                            transform="translateX(-50%)"
                            p={4}
                            zIndex="999"
                            position="fixed"
                        >
                            <Button backgroundColor="#5AB2AD" color="#FFFFFF" h="2.2em" fontWeight={'bold'} type="submit" borderRadius="0.8em" mb={'2em'}>
                                Acessar
                            </Button>
                        </VStack>
                    </>)}
                </VStack>
            </Formik>
        </Flex>

    );
}

export default SelectEmpresa;

