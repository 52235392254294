import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Button,
  useDisclosure,
  VStack,
  chakra,
  Flex,
  Text,
  Radio,
  Img,
  useToast,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Carrinho from '../../../assets/images/carrinho.svg';
import { useContext } from 'react';
import { AccountContext } from '../../AccountContext';
import { DeleteIcon } from '@chakra-ui/icons';
import ModalReenvioMsg from '../Modal/Exame/ModalReenvioMsg';
function DrawerCarrinho(props) {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AccountContext);
  const disclosure = props.disclosure;
  const cart = user.cart;
  const { state } = useLocation();
  const { itens, valor } = cart || {};
  let url = window.location.href;
  const [currentIndex, setCurrentIndex] = useState();
  const reenvioMsg = useDisclosure();
  const toast = useToast();

  function validaCart() {
    if (
      (user.cart?.itens === null &&
        user.cart?.prescricao?.length === 0 &&
        user.cart?.consultas === undefined &&
        user.cart?.atestado === undefined &&
        user.cart?.arealivre === undefined) ||
      (user.cart.prescricao === null &&
        user.cart.itens === null &&
        user.cart.consultas === null &&
        user.cart.atestado === null &&
        user.cart.arealivre === null) ||
      (user.cart.prescricao === undefined &&
        user.cart.itens === undefined &&
        user.cart.consultas === undefined &&
        user.cart.atestado === undefined &&
        user.cart.arealivre === undefined) ||
      (user.cart.prescricao === null &&
        user.cart.itens === undefined &&
        user.cart.consultas === undefined &&
        user.cart.atestado === undefined &&
        user.cart.arealivre === undefined) ||
      (user.cart.itens === null &&
        user.cart.prescricao === undefined &&
        user.cart.consultas === undefined &&
        user.cart.atestado === undefined &&
        user.cart.arealivre === undefined) ||
      (user.cart.consultas === null &&
        user.cart.itens === undefined &&
        user.cart.prescricao === undefined &&
        user.cart.atestado === undefined &&
        user.cart.arealivre === undefined) ||
      (user.cart.atestado === null &&
        user.cart.itens === undefined &&
        user.cart.consultas === undefined &&
        user.cart.prescricao === undefined &&
        user.cart.arealivre === undefined) ||
      (user.cart.arealivre === null &&
        user.cart.itens === undefined &&
        user.cart.consultas === undefined &&
        user.cart.atestado === undefined &&
        user.cart.prescricao === undefined)
    ) {
      setUser((prevState) => ({ ...prevState, cart: null }));
      return { vazio: true };
    } else {
      return { vazio: false };
    }
  }

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  const useProcedimentos = cart?.itens?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.nome}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.itens.filter((cartItem) => cartItem !== item);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: null } }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, itens: updatedItens } }));
                }
                validaCart();
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });

  const usePrescricao = cart?.prescricao?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.droga}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.prescricao.filter((cartItem) => cartItem !== item);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, prescricao: null } }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, prescricao: updatedItens } }));
                }
                validaCart();
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });

  const useATestado = cart?.atestado?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item > 10 ? `${item.substring(0, 10)}...` : item}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.atestado.filter((_, i) => i !== index);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, atestado: null } }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, atestado: updatedItens } }));
                }
                validaCart();
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });

  const useConsulta = cart?.consultas?.map((item, index) => {
    return (
      <>
        <chakra.tr w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
          <Flex mt="1em">
            <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
              {item.dados.nome}
            </Text>
            <DeleteIcon
              color="#EB8B2A"
              onClick={() => {
                const updatedItens = cart.consultas.filter((cartItem) => cartItem !== item);
                console.log('updatedItens', updatedItens);
                if (updatedItens.length === 0) {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, consultas: null } }));
                } else {
                  setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, consultas: updatedItens } }));
                }
                validaCart();
              }}
            />
          </Flex>
        </chakra.tr>
      </>
    );
  });

  const useAreaLivre = cart?.arealivre?.map((item, index) => {
    return (
      <chakra.tr key={index} w="100%" borderBottom="1px solid" borderColor="#AEAEB2">
        <Flex mt="1em">
          <Text fontSize="xs" w="90%" color="#585858" alignSelf="flex-end">
            {typeof item === 'object'
              ? item.descricao.length > 10
                ? `${item.descricao.substring(0, 10)}...`
                : item.descricao
              : item.length > 10
              ? `${item.substring(0, 10)}...`
              : item}
          </Text>
          <DeleteIcon
            color="#EB8B2A"
            onClick={() => {
              const updatedItens = cart.arealivre.filter((cartItem) => cartItem !== item);
              if (updatedItens.length === 0) {
                setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, arealivre: null } }));
              } else {
                setUser((prevState) => ({ ...prevState, cart: { ...prevState.cart, arealivre: updatedItens } }));
              }
              validaCart();
            }}
          />
        </Flex>
      </chakra.tr>
    );
  });

  return (
    <>
      <ModalReenvioMsg
        disclosure={reenvioMsg}
        paciente_data={{ ...user.cart, paciente: state.paciente, empresa_id: user.empresa_id, operador_id: user.operador_id, token: user.tokenBird }}
      />
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderTopRadius={20} width="100%" h="80%">
          <DrawerBody pb="10%" pt="10%">
            {' '}
            <VStack mt="5%">
              <Radio w="3em" autoFocus hidden></Radio>
              <chakra.h1 fontWeight="bold" pb="10%">
                Última Solicitação
              </chakra.h1>
              {cart ? (
                <>
                  <Box
                    w="90%"
                    mx="auto"
                    alignItems="center"
                    overflow="hidden"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="10px"
                    padding="1em"
                    marginTop={5}
                    px={5}
                    pt={5}
                  >
                    <chakra.h2 marginTop="5%" fontWeight="bold">
                      PROCEDIMENTOS
                    </chakra.h2>
                    <chakra.table w="100%">{useProcedimentos}</chakra.table>

                    <chakra.h2 marginTop="5%" fontWeight="bold">
                      PRESCRIÇÃO
                    </chakra.h2>
                    <chakra.table w="100%">{usePrescricao}</chakra.table>

                    <chakra.h2 marginTop="5%" fontWeight="bold">
                      ATESTADO
                    </chakra.h2>
                    <chakra.table w="100%">{useATestado}</chakra.table>

                    <chakra.h2 marginTop="5%" fontWeight="bold">
                      CONSULTA
                    </chakra.h2>
                    <chakra.table w="100%">{useConsulta}</chakra.table>

                    <chakra.h2 marginTop="5%" fontWeight="bold">
                      ÁREA LIVRE
                    </chakra.h2>
                    <chakra.table w="100%">{useAreaLivre}</chakra.table>

                    <Button
                      mt="10%"
                      width="100%"
                      colorScheme="teal"
                      onClick={() => {
                        if (validaCart().vazio) {
                          callToast('Importante', 'Você precisa adicionar itens ao seu carrinho de solicitações para continuar', 'error');
                          return;
                        }
                        reenvioMsg.onOpen();
                        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/request`, {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            credentials: 'include',
                          },
                          body: JSON.stringify({
                            ...user.cart,
                            paciente: state.paciente,
                            empresa_id: user.empresa_id,
                            operador_id: user.operador_id,
                            token: user.tokenBird,
                          }),
                        });
                        reenvioMsg.onOpen();
                        //navigate('/alerts/sucesso/default');
                      }}
                    >
                      Concluir
                    </Button>
                    <Button
                      mt="10%"
                      width="100%"
                      colorScheme="gray"
                      onClick={() => {
                        setUser((prevState) => ({ ...prevState, cart: null }));
                      }}
                    >
                      Esvaziar
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Img boxSize="5em" src={Carrinho} />
                  <chakra.h1 fontWeight="bold">Lista vazia!</chakra.h1>
                  <chakra.h2 fontColor="#585858" w="55%" align="center">
                    Você não possue nenhuma solicitação pendente.
                  </chakra.h2>
                </>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerCarrinho;
