import {
  Button,
  useToast,
  ButtonGroup,
  Heading,
  Text,
  VStack,
  chakra,
  Img,
  Input,
  Center,
  Grid,
  GridItem,
  Switch,
  FormLabel,
  Flex,
  HStack,
  FormControl,
} from '@chakra-ui/react';
import { React, useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import TextField from '../../Utils/Inputs/TextField';
import Loading from '../../Utils/Loading';
import Select from '../../Utils/Inputs/SelectField';
import SelectSearch from '../../Utils/Inputs/SelectFieldSearch2';
import * as Yup from 'yup';
import validaCPF from '../../Utils/Functions/validaCPF';

function DadosBancriosClinica() {
  const { setUser, setPage, menuDisclosure } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const { user } = useContext(AccountContext);
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const [empresa, setEmpresa] = useState(null);
  const [bankData, setBankData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState();
  const listbanks = banks ? banks.map((bank) => bank.nome) : [];
  setPage('Dados Bancários');
  const [documento, setValue] = useState('');
  const [renderFormik, setRenderFormik] = useState(false);
  const onlyNumbers = (str) => str?.replace(/[^0-9]/g, '');
  function showToast() {
    toast({
      title: '',
      position: 'top',
      description: 'Sistema em Manutenção',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  async function getDatas() {
    const urlBanks = process.env.REACT_APP_BASE_URL_API_SERVER + '/banks/';
    const requestBanks = await fetch(urlBanks)
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          showToast();
          return;
        }
        return res.json();
      })
      .then((result) => {
        if (result.success) {
          setBanks(result.data);
        } else {
          setError('problema ao cadastrar, tente novamente mais tarde');
        }
        setLoading(false);
      });
  }

  /*
   
  }*/

  useEffect(() => {
    getDatas();
  }, []);
  const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectEmpresarById/${user.empresa_id}`;
  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const postsData = await response.json();
      setEmpresa(postsData.data);
    } catch (error) { }


  }

  function validateCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Eliminate known invalid CNPJs
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Validate DVs
    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(0))
      return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result != digits.charAt(1))
      return false;

    return true;

  }

  const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
  const bankAccountTypes = ['Conta corrente', 'Conta poupança', 'Conta corrente conjunta', 'Conta poupança conjunta'];
  const defaultArgs = { borderRadius: -0.5, w: '100%', maskChar: null };
  const defaultArgsSelect = { textAlign: 'left', fontWeight: 'normal', variant: 'outline', onChange: () => { } };
  const trimmedCod = empresa && empresa?.length > 0 ? parseInt(empresa[0]?.codbanco, 10) : 0;
  const banco = empresa && empresa?.length > 0 ? banks?.find((bank) => bank.banco_id === trimmedCod) : 0;

  const banco_index = empresa && empresa?.length > 0 && banco ? banks.indexOf(banco) : '';

  useEffect(() => {
    const applyMask = (documento) => {
      const cleanedValue = documento.replace(/\D/g, '');

      let maskedValue = '';
      if (cleanedValue.length <= 11) {
        maskedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (cleanedValue.length > 11) {
        maskedValue = cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      } else {
        maskedValue = cleanedValue;
      }
      setValue(maskedValue);
      return maskedValue;
    };

    if (empresa) {
      const doc = empresa[0]?.cpf ? empresa[0]?.cpf : empresa[0]?.ndtc
      const documentoFormado = applyMask(doc);
      setValue(documentoFormado);
      setRenderFormik(true);
    }
  }, [empresa]);


  const FormularioEditar = ({ formikProps }) => {
    const { setFieldValue, values, isSubmitting, getFieldMeta } = formikProps;

    function applyMask(documento) {
      function applyMaskField() {
        try {
          const cleanedValue = documento.replace(/\D/g, '');
          let maskedValue = '';

          if (cleanedValue.length <= 11) {
            maskedValue = cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          } else if (cleanedValue.length > 11) {
            maskedValue = cleanedValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
          } else {
            maskedValue = cleanedValue;
          }

          setFieldValue('documento', maskedValue);
        } catch (error) {
          // Handle error if needed
        }
      }

      applyMaskField();
    }

    const CampoDocumento = () => {
      return (
        <>
          <TextField
            name="documento"
            label="CPF/CNPJ*"
            textAlign="left"
            fontSize="0.7em"
            color="#939598"
            backgroundColor="#EFEFEF"
            maskChar={null}
            type="tel"
            h="3.8em"
            fontWeight="bold"
            borderRadius="-0.5em"
            maxLength={25}
            onChange={(e) => {
              const unmaskedValue = e.target.value.replace(/\s/g, '');
              setFieldValue('documento', unmaskedValue);
              if (e.currentTarget.value.length >= 10) applyMask(e.currentTarget.value);
            }}
          />
        </>
      );
    };

    return <>{CampoDocumento()}</>;
  };

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }

  useEffect(() => {
    fetchData(url);
  }, []);
  return (
    <>
      <NoUserHeader />
      {empresa && banks && listbanks ? (
        <VStack>
          {' '}
          {renderFormik && (
            <Formik
              initialValues={{
                banco: empresa && empresa?.length > 0 ? banco_index : '',
                tipo_conta: empresa && empresa?.length > 0 ? bankAccountTypesPagarme.indexOf(empresa[0]?.tipoconta) : '',
                titular: empresa && empresa?.length > 0 ? empresa[0]?.nomeconta : '',
                documento: empresa && empresa?.length > 0 ? documento : '',
                agencia: empresa && empresa?.length > 0 ? empresa[0]?.numagencia : '',
                numero_conta: empresa && empresa?.length > 0 ? empresa[0]?.numconta : '',
                digito_validador: empresa && empresa?.length > 0 ? empresa[0]?.digvalconta : ''
              }}
              validateOnChange={false}
              validationSchema={Yup.object({
                banco: Yup.number().required('Banco é obrigatório!'),
                tipo_conta: Yup.string().required('Tipo conta é obrigatório!'),
                titular: Yup.string()
                  .required('O nome do titular é obrigatório!')
                  .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s0-9']+$/, 'O nome do titular não deve conter caracteres especiais, exceto números')
                  .max(30, 'O nome do titular não pode ter mais de 30 caracteres'),
                documento: Yup.string()
                  .required('Documento é obrigatório')
                  .test('validacao-documento', 'Documento Inválido', function (value) {
                    if (!value) return false;

                    const cleanedValue = value.replace(/\D/g, '');
                    if (cleanedValue.length === 11) {
                      const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
                      return validaCPF(cleanedValue)
                    } else {
                      const cnpjRegex = /^\d{14}$/;
                      return cnpjRegex.test(cleanedValue);
                    }
                  }).min(8, 'O documento deve ter no mínimo 11 dígitos'),
                numero_conta: Yup.string()
                  .matches(/^[0-9]+$/, 'Número da conta deve conter apenas números')
                  .required('Número da conta é obrigatório!')
                  .max(13, 'O número da conta não pode ter mais de 13 caracteres'),
                digito_validador: Yup.string()
                  .matches(/^[0-9]+$/, 'Dígito validador deve conter apenas números')
                  .required('Dígito validador é obrigatório!')
                  .max(2, 'O dígito validador não pode ter mais de 2 caracteres'),
                agencia: Yup.string()
                  .matches(/^[0-9]+$/, 'Agência deve conter apenas números')
                  .required('Agência é obrigatório!')
                  .max(4, 'Agência não pode conter mais de 4 caracteres'),
              })}
              onSubmit={(values, actions) => {
                const vals = { ...values };
                if (user.empresa_valida === true) {
                  if (onlyNumbers(vals.documento) !== onlyNumbers(documento)) {
                    callToast('Importante', 'Você não pode alterar o documento da sua conta bancária', 'error')
                    return
                  }
                }
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/updateCompanyAccount`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: { 'content-type': 'application/json' },
                  body: JSON.stringify({
                    banco: vals.banco !== null ? banks[vals.banco].codigo : empresa[0]?.codbanco,
                    tipo_conta: bankAccountTypesPagarme[vals.tipo_conta],
                    titular: vals.titular,
                    documento: onlyNumbers(vals.documento),
                    pix: vals.pix,
                    numero_conta: vals.numero_conta,
                    chave_pix: vals.chave_pix,
                    empresa_id: empresa[0]?.empresa_id,
                    agencia: vals.agencia,
                    digito_validador: vals.digito_validador,
                    id_recipient: empresa && empresa[0] && empresa[0]?.id_recipient ? empresa[0]?.id_recipient : [],
                    document: empresa && empresa[0] && empresa[0]?.cnpj ? empresa[0]?.cnpj : [],
                    empresa_valida: user.empresa_valida,
                    empresa_nome: user.empresa,
                  }),
                })
                  .catch((err) => {
                    return err;
                  })
                  .then(async (response) => {
                    if (!response || !response.ok || response.status >= 400) {
                    }
                    return response.json();
                  })
                  .then(async (result) => {
                    //callToast(title, description, status)
                    if (!result) return;

                    setUser((prevState) => ({
                      ...prevState,
                      empresa_valida: result?.data?.database[0]?.is_valid === true ? true : false,
                    }));
                    console.log(user)
                    console.log('RESULTADO ATUALIZAÇÃO PIX ', result?.data?.database[0])

                    if (result.message === 'saldo em conta') {
                      callToast('Importante', 'Não foi possível criar uma nova conta bancária pois sua atual conta possui saldo financeiro. Entre em contato com o suporte para mais informações', 'error')
                      return
                    }

                    if (result.message === 'nova conta cadastrada') {
                      //callToast('Importante', 'Uma nova conta bancária foi cadastrada', 'success')
                      menuDisclosure.onClose();
                      navigate('/alerts/sucesso/default');
                      return
                    }

                    if (result.message === 'error nova conta') {
                      callToast('Importante', 'Não foi possível criar uma nova conta bancária. Entre em contato com o suporte para mais informações', 'error')
                      return
                    }

                    if (result.message === 'empresa localizada') {
                      // callToast('Importante', 'Sucesso ao atualizar os dados bancários', 'success')
                      menuDisclosure.onClose();
                      navigate('/alerts/sucesso/default');
                      return
                    }

                  });
              }}
              validateOnBlur={false}
            >
              {(props) => {
                const { setFieldValue } = props;
                return (
                  <VStack as={Form} w="100%">
                    <VStack w="90%" textAlign={'center'}>
                      <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'left'} color="#5AB2AD" noOfLines={3} maxWidth="90%">
                        Dados bancários para repasse dos rendimentos da clínica.
                      </Text>
                      <FormControl>
                        <FormLabel htmlFor="titular" fontSize="0.7em" color="#939598" fontWeight="bold">
                          Banco*
                        </FormLabel>
                        <SelectSearch
                          {...defaultArgs}
                          {...defaultArgsSelect}
                          name="banco"
                          placeholder="Banco"
                          options={listbanks}
                          fontSize="0.7em"
                          backgroundColor="#EFEFEF"
                          w="100%"
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="titular" fontSize="0.7em" color="#939598" fontWeight="bold">
                          Tipo conta bancária*
                        </FormLabel>

                        <Select {...defaultArgs} {...defaultArgsSelect} name="tipo_conta" placeholder="Selecione o tipo de conta" options={bankAccountTypes} fontSize="0.7em"
                          backgroundColor="#EFEFEF"
                          w="100%" />
                      </FormControl>
                      <TextField
                        label="Titular da conta*"
                        name="titular"
                        textAlign="left"
                        fontSize="0.7em"
                        color="#939598"
                        backgroundColor="#EFEFEF"
                        maskChar={null}
                        type="text"
                        h="3.8em"
                        fontWeight="bold"
                        borderRadius="-0.5em"
                        maxLength={30}
                      />
                      <HStack flexDirection={'row'} w="100%">
                        <FormularioEditar formikProps={props} />

                        <TextField
                          name="agencia"
                          label="Agência (sem dígito)*"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="tel"
                          h="3.8em"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                          maxLength={4}
                        />
                      </HStack>

                      <HStack flexDirection={'row'} w="100%">
                        <TextField
                          name="numero_conta"
                          label="N° da conta*"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          type="tel"
                          h="3.8em"
                          w="100%"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                          maxLength={13}
                        />{' '}
                        <TextField
                          name="digito_validador"
                          label="Dígito validador da conta"
                          textAlign="left"
                          fontSize="0.7em"
                          color="#939598"
                          backgroundColor="#EFEFEF"
                          maskChar={null}
                          mask="9"
                          type="tel"
                          h="3.8em"
                          w="100%"
                          fontWeight="bold"
                          borderRadius="-0.5em"
                          maxLength={2}
                        />
                      </HStack>

                      <ButtonGroup flexDirection={'column'} w="70%" pt="10%">
                        <Button backgroundColor="#95F3EE" color="#5AB2AD" h="3em" type="submit" borderRadius="2em">
                          Concluir
                        </Button>
                      </ButtonGroup>
                    </VStack>
                  </VStack>

                );
              }}
            </Formik>
          )}
        </VStack >

      ) : (
        <>
          <Loading />
        </>
      )
      }
    </>
  );
}

export default DadosBancriosClinica;
