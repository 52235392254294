import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Button,
  DrawerHeader,
  HStack,
  ButtonGroup,
  DrawerOverlay,
  PinInput,
  PinInputField,
  Text,
  VStack,
  useToast,
  Radio,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

function ConfirmacaoWhatsapp(prop) {
  const disclosure = prop.disclosure;
  const userData = prop.paciente_data;
  const [error, setError] = useState(null);
  const [pin, setPin] = useState('');
  const type = prop.type;
  const navig_to = prop.navig_to;
  const navigate = useNavigate();
  const handleChange = (pin) => {
    setPin(pin);
  };
  const toast = useToast();
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }

  return (
    <>
      <VStack>
        <Drawer placement={'bottom'} isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
          <DrawerOverlay />
          <DrawerContent backgroundColor="#5AB2AD" height={{ md: '45%', sm: '50%', lg: '50%' }} borderTopRadius={'3.2em'} p="15% 5%">
            <DrawerHeader>
              <Text fontSize="0.6em" lineHeight="1em" color="#FFFFFF" fontWeight={'bold'} textAlign={'center'} noOfLines={4} maxWidth="100%">
                Digite abaixo o código de confirmação enviado para o whatsapp cadastrado
              </Text>
            </DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={false}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  const vals = {
                    codigo: pin,
                    fone: userData.whatsapp,
                    medico: userData.medico,
                    documento: userData.medico === true ? userData.cpf_gestor : userData.documento,
                  };
                  console.log('vals', vals);
                  fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/confirm_whatsapp`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(vals),
                  })
                    .catch((err) => {
                      console.log('err', err);
                      return;
                    })
                    .then((res) => {
                      if (!res || !res.ok || res.status >= 400) {
                        setError('Erro na Rede', res);
                        return res.json();
                      }
                      if (res.status === 200) {
                        console.log('res', res);
                        setError('');
                      }
                      return res.json();
                    })
                    .then((data) => {
                      console.log('data', data);
                      if (!data) return;
                      if (data.success === false) {
                        callToast('Importante', 'Codigo Incorreto. Tente novamente', 'error');
                        setPin('');
                        return;
                      }
                      if (data.success === true) {
                        console.log('sucesso');
                        navigate(navig_to, {
                          state: {
                            ...userData,
                          },
                        });
                      }
                    });
                }}
              >
                <VStack as={Form}>
                  <Radio w="3em" autoFocus hidden></Radio>
                  <HStack w="100%">
                    <PinInput type="numeric" size="md" name="codigo" value={pin} onChange={handleChange} otp>
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                      <PinInputField backgroundColor={'#ffff'} h="2.5em" type='numeric' />
                    </PinInput>
                  </HStack>
                  <ButtonGroup flexDirection={'column'} w="70%" pt="20%" ml="15%">
                    <Button backgroundColor="#ffff" color="#5AB2AD" h="3em" type="submit" borderRadius="2em">
                      Confirmar
                    </Button>
                  </ButtonGroup>
                </VStack>
              </Formik>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </VStack>
    </>
  );
}

export default ConfirmacaoWhatsapp;
