import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import { AccountContext } from '../../AccountContext';
import { Button, Flex, HStack, Heading, SelectField, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, chakra, useDisclosure, useToast } from '@chakra-ui/react';
import { MdShare } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import { format } from 'date-fns';
import ModalReenvioExames from '../../Utils/Modal/Medicos/ModalReenvioExames';
import DrawerHistorico from './DrawerHistorico';
function DetalhesHistorico() {
    const { state } = useLocation();
    const { atendimento } = state;
    const toast = useToast();
    console.log(atendimento)
    const { user, setPage } = useContext(AccountContext);
    const [documentos, setDocumentos] = useState(false);
    const [ambulatorio_laudo, setAmbulatorioLaudo] = useState([]);
    setPage('Detalhes histórico');
    const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');
    const ENDPOINT_DEOVITA = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_URL_DEOVITA_PROD : process.env.REACT_APP_URL_DEOVITA_TEST;
    function myToLowerCase(text) {
        if (typeof text === 'string') {
            return text.toLocaleLowerCase();
        } else {
            return text;
        }
    }

    async function resultadoExames() {
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/requestOrcamento`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ambulatorio_orcamento_id: atendimento.ambulatorio_orcamento_id }),
        })
            .catch(async (err) => {
                console.log(err);
                return;
            })
            .then(async (res) => {
                if (!res || !res.ok || res.status >= 400) {
                    return;
                }
                return await res.json();
            }).then(async (resposta_data) => {
                return await resposta_data

            }).then(async (resposta_final) => {
                if (resposta_final?.data === 0) {
                    callToast('Importante', 'Não há resultados de exames disponíveis para esta solicitação', 'error')
                    return
                }
                const response = await fetch(
                    `${ENDPOINT_DEOVITA}/deovita/autocomplete/retrievePrescriptionAssignatureUrlsForLaudoId/${resposta_final?.data?.ambulatorio_laudo_id}`
                );
                const data = await response.json();

                setDocumentos(data);
                if (resposta_final?.data?.ambulatorio_laudo_id > 0) {
                    historicoDisclousure.onOpen()
                } else {
                    callToast('Importante', 'Não há resultados de exames disponíveis para esta solicitação', 'error')
                }

            })
    }

    function callToast(title, description, status) {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 4000,
            isClosable: true,
            position: 'bottom',
            colorScheme: 'red',
            containerStyle: {
                paddingTop: '50px',
            },
        })
    }

    const whatsappDisclousure = useDisclosure();
    const historicoDisclousure = useDisclosure();
    return (
        <>
            <NoUserHeader />
            <ModalReenvioExames disclosure={whatsappDisclousure} atendimento={atendimento} />
            <DrawerHistorico disclosure={historicoDisclousure} documentos={documentos} />
            <Flex
                minW="210px"
                maxW="340px"
                mx="auto"
                alignItems="center"
                overflow="hidden"
                border="1px solid"
                borderColor={'orange'}
                borderRadius="10px"
                padding="1em"
                marginTop={5}
                px={5}
                pt={5}
            >
                <VStack alignItems="right" spacing={2.5} width="100%">
                    <Heading fontSize="1em">
                        AGENDAMENTO DE { atendimento?.itens?.length > 0 ? 'EXAMES' : 'CONSULTA'}
                    </Heading>

                    <chakra.p fontSize="0.8em">
                        <Text casing="capitalize">
                            <chakra.span fontWeight="bold">PACIENTE: </chakra.span>
                            {myToLowerCase(atendimento.paciente_nome)}
                        </Text>
                    </chakra.p>

                    <chakra.p fontSize="0.8em">
                        <chakra.span fontWeight="bold">  Data: </chakra.span>
                        {formatDate(atendimento.data_cadastro)}
                    </chakra.p>

                    {/* <chakra.p fontSize="0.8em">
                        <chakra.span fontWeight="bold">  Status: </chakra.span>
                        {
                            atendimento.orcamento_recusado === true ? 'Recusado' :
                                atendimento.orcamento_ativo === true && atendimento.orcamento_recusado === false ? 'Pendente' :
                                    atendimento.orcamento_ativo === true && atendimento.orcamento_recusado === false ? 'Orçamento ativo' :
                                        'Realizado'
                        }
                    </chakra.p> */}
                    {
                        atendimento?.itens?.length > 0 ?
                        <chakra.p fontSize="0.8em">
                            <chakra.span fontWeight="bold"> Procedimentos: </chakra.span>
                            {atendimento?.itens?.map((solicitacao_item) => (
                                <chakra.li key={solicitacao_item.id}>{solicitacao_item.nome}</chakra.li>
                            ))}
                        </chakra.p>
                        : <></>

                    }


                    {/*  <chakra.p fontSize="0.8em">
                        <Text casing="capitalize">
                            <chakra.span fontWeight="bold">LOCAL: </chakra.span>
                            {atendimento?.empresa.toUpperCase()} - {myToLowerCase(atendimento.endereco)}, {myToLowerCase(atendimento.numero)} - {myToLowerCase(atendimento.cidade)}
                        </Text>
                    </chakra.p>*/}

                    <Button colorScheme="green" variant="outline" leftIcon={<FaWhatsapp />} onClick={() => { whatsappDisclousure.onOpen() }}>
                        <HStack spacing={5} >
                            <Text>Reenviar</Text>
                        </HStack>
                    </Button>

                    {atendimento?.itens?.length > 0 ?
                        <Button colorScheme="teal" variant="outline" >
                            <HStack spacing={5} onClick={() =>
                                resultadoExames()
                                //callToast('Importante', 'Opção indisponível', 'error')
                            }>
                                <Text>Resultado de Exames</Text>
                            </HStack>
                        </Button>
                    :
                    <></>


                    }



                    {/* <Button colorScheme="teal" variant="outline" marginTop={4} leftIcon={<MdShare />}>
                        Compartilhar
                    </Button> */}
                </VStack >
            </Flex >
        </>
    )
}

export default DetalhesHistorico