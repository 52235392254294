import {
  Button,
  ButtonGroup,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import png from '../../../../assets/images/nao-cadastrado.svg';
import sair from '../../../../assets/images/sairModal.svg';
import ConfirmacaoWhatsapp from '../../../Utils/Modal/Cadastro/ConfirmacaoWhatsapp';
import { useLocation, useNavigate } from 'react-router-dom';
function OperadorJaCadastrado(prop) {
  const cpf_gestor = prop.cpf_gestor;
  const thisModal = prop.disclosure;
  const navigate = useNavigate();
  const { state } = useLocation();
  const confirmarWpp = useDisclosure();
  const [userData, setUserData] = useState({});
  const dataClient = prop.dataClient;
  console.log(state);
  const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');
  function validarWpp(wpp, nome, cpf) {
    const medico = 'true';
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/confirma_wpp_empresa/${wpp}/${nome}/${cpf}/${medico}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then();
    setUserData({
      ...state,
      whatsapp: onlyNumbers(dataClient.data.whatsapp),
      cpf_gestor: onlyNumbers(dataClient.data.cpf),
      nome: onlyNumbers(dataClient.data.nome),
      medico: true,
    });
    thisModal.onClose();
    confirmarWpp.onOpen();
  }

  return (
    <>
      <ConfirmacaoWhatsapp disclosure={confirmarWpp} paciente_data={userData} navig_to="/repassefinanceiro" />
      <Modal isOpen={thisModal.isOpen} onClose={thisModal.onClose} isCentered>
        <ModalOverlay />

        <ModalContent width="90%" borderRadius="1.5em">
          <ModalBody pt="2.5em">
            <Heading size="md" ml="3" mt="2.5em">
              <Text fontSize="1em" lineHeight="1em" color="#939598" fontWeight={'bold'} textAlign={'center'} noOfLines={5} maxWidth="100%">
                Opa! Você já possui cadastro conosco.
              </Text>
            </Heading>
            <VStack>
              <VStack flexDirection={'column'} w="100%" pt="15%">
                <Button
                  backgroundColor="#4D9140"
                  type="submit"
                  onClick={() => {
                    validarWpp(dataClient.data.whatsapp, dataClient.data.nome, dataClient.data.cpf);
                  }}
                  w="100%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Validar Whatsapp
                </Button>

                <Button
                  backgroundColor="orange.400"
                  type="submit"
                  onClick={() => {
                    thisModal.onClose();
                  }}
                  w="100%"
                  color="#ffff"
                  h="3em"
                  borderRadius="0.8em"
                >
                  Cadastrar outro CPF
                </Button>
              </VStack>
            </VStack>
          </ModalBody>
          <VStack ml="4">
            <ModalFooter></ModalFooter>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OperadorJaCadastrado;
