import {
  Button,
  ButtonGroup,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Heading,
  useDisclosure,
  Stack,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import MainHeader from '../../Utils/Headers/MainHeader';
import { Form, Formik } from 'formik';
import TextField from '../../Utils/Inputs/TextField';
import validaCPF from '../../Utils/Functions/validaCPF';
import * as Yup from 'yup';
import { json, useNavigate } from 'react-router-dom';
import { differenceInYears, parse } from 'date-fns';
import PacienteNaoEncontrado from '../../Utils/Modal/Dashboard/PacienteNaoEncontrado';
import { AccountContext } from '../../AccountContext';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

function Main() {
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useContext(AccountContext);
  const [paciente, setPaciente] = useState();
  const naoEncontradoDisclosure = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  function showToast() {
    toast({
      title: '',
      position: 'bottom',
      description: 'Apenas médicos podem utilizar essa função',
      status: 'error',
      duration: 2000,
      isClosable: true,
      containerStyle: {
        paddingBottom: '60%',
      },
      action: (
        <Button colorScheme="red" size="sm" ml={4} onClick={() => navigate('/validacaobird')}>
          Botão
        </Button>
      ),
    });
  }
  return (
    <>
      <MainHeader />

      <PacienteNaoEncontrado disclosure={naoEncontradoDisclosure} />
      <VStack
        align="center"
        justify={{
          base: 'center',
          md: 'space-around',
          xl: 'space-between',
        }}
        direction={{ base: 'column-reverse' }}
        wrap="no-wrap"
        minH="10vh"
        px={8}
        mt="5%"
      >
        {' '}
        <Formik
          initialValues={{ cpf: '' }}
          validateOnChange={false}
          validationSchema={Yup.object(
            paciente !== undefined && paciente === null
              ? {
                  cpf: Yup.string()
                    .required('CPF Obrigatório!')
                    .trim()
                    .test('valida_cpf', 'CPF inválido', (cpf) => validaCPF(cpf)),
                  nascimento: Yup.date()
                    .max(new Date(), 'Futuro não é permitido')
                    .min(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), 'Data inválida')
                    .transform((value, originalValue) => {
                      return parse(originalValue, 'dd/MM/yyyy', new Date());
                    })
                    .required('Data de nascimento é obrigatória!')
                    .test('age18', 'O títular deve ser maior de 18 anos', (value) => {
                      let today = new Date();
                      let age = differenceInYears(today, value);
                      return age >= 18;
                    })
                    .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),
                  whatsapp: Yup.string()
                    .required('Whatsapp é obrigatório')
                    .trim()
                    .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
                }
              : {
                  cpf: Yup.string()
                    .required('CPF Obrigatório!')
                    .trim()
                    .test('valida_cpf', 'CPF inválido', (cpf) => validaCPF(cpf)),
                }
          )}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            setLoading(true);
            if (!user.crm) {
              showToast();
              return;
            } else {
              const vals = { ...values };
              let cpf = onlyNumbers(vals.cpf);
              if (paciente === undefined) {
                fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/patients/getClientByCPF/${cpf}`, {
                  method: 'GET',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                  .catch((err) => {
                    return err;
                  })
                  .then((res) => {
                    if (!res || !res.ok || res.status >= 400) {
                      setLoading(false);
                    }
                    return res.json();
                  })
                  .then((response) => {
                    if (!response) return;
                    if (response.data) {
                      setUser((prevState) => ({
                        ...prevState,
                        cart: null,
                      }));
                      setPaciente(response.data);
                      navigate('/solicitacao/confere-paciente', { state: { paciente: { ...response.data } } });
                    } else {
                      naoEncontradoDisclosure.onOpen();
                      setPaciente(null);
                    }
                  });
              } else {
                if (paciente === null) {
                  let nascimento = vals.nascimento;
                  let nome = vals.nome;
                  let whatsapp = onlyNumbers(vals.whatsapp);
                  const payload = { cpf, whatsapp, nascimento, nome };
                  fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/patients/registerClient/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  })
                    .catch((err) => {
                      return err;
                    })
                    .then((res) => {
                      if (!res || !res.ok || res.status >= 400) {
                      }
                      return res.json();
                    })
                    .then((response) => {
                      setUser((prevState) => ({
                        ...prevState,
                        cart: null,
                      }));
                      navigate('/solicitacao/confere-paciente', { state: { paciente: { ...response.data } } });
                    });
                } else {
                  setUser((prevState) => ({
                    ...prevState,
                    cart: null,
                  }));
                  navigate('/solicitacao/confere-paciente', { state: { paciente: { paciente } } });
                }
              }
            }
          }}
        >
          <VStack as={Form} w="full" spacing={paciente !== undefined && paciente === null ? {} : { md: '13em', sm: '3em', lg: '4em' }}>
            <TextField
              name="cpf"
              label="Digite abaixo o CPF do paciente*"
              placeholder="000.000.000-00"
              textAlign="center"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              mask="999.999.999-99"
              maskChar={null}
              type="tel"
              autoComplete="off"
            />
            {paciente !== undefined && paciente === null ? (
              <>
                <TextField
                  name="nome"
                  label="Nome*"
                  placeholder="Digite o nome"
                  textAlign="center"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  maskChar={null}
                  type="text"
                  autoComplete="off"
                />

                <TextField
                  name="nascimento"
                  label="Digite a data de nascimento*"
                  placeholder="Digite a data de nascimento"
                  textAlign="center"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  mask="99/99/9999"
                  maskChar={null}
                  type="tel"
                  autoComplete="off"
                />
                <TextField
                  name="whatsapp"
                  label="Whatsapp*"
                  placeholder="Digite o whatsapp do paciente"
                  textAlign="center"
                  fontSize="0.7em"
                  color="#939598"
                  backgroundColor="#EFEFEF"
                  mask="(99) 99999-9999"
                  maskChar={null}
                  type="tel"
                  autoComplete="off"
                />
              </>
            ) : (
              <></>
            )}
            <ButtonGroup pt={paciente !== undefined && paciente === null ? '10%' : ''}>
              <Button backgroundColor="#95F3EE" color="#5AB2AD" w="13em" h="3em" type="submit" borderRadius="2em">
                {paciente !== undefined && paciente === null ? 'Cadastrar' : paciente !== undefined && paciente !== null ? 'Seguir' : 'Buscar'}
              </Button>
            </ButtonGroup>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default Main;
