import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Heading,
  HStack,
  Img,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Pills from '../../../assets/images/icons/actions/pills.svg';
import AreaLivre from '../../../assets/images/icons/actions/area_livre.svg';
import Solicitar_exames from '../../../assets/images/icons/actions/solicitar_exames.svg';
import Prescrever_atestado from '../../../assets/images/icons/actions/prescrever_atestado.svg';
import Prescrever_medicamentos from '../../../assets/images/icons/actions/prescrever_medicamentos.svg';

import { AccountContext } from '../../AccountContext';
import { useToast } from '@chakra-ui/react';
import ModalReenvioMsg from '../Modal/Exame/ModalReenvioMsg';
function ActionChoice(props) {
  const { user } = useContext(AccountContext);
  const disclosure = props.disclosure;
  const type = props.type;
  const submit = props.submit;
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const toast = useToast();
  const reenvioMsg = useDisclosure();
  function callToast() {
    toast({
      title: 'Importante',
      description: `A empresa em que você está tentando realizar solicitação está com restrição de acesso devido problemas no cadastro da conta bancaria. ${
        user.perfil_id === 10
          ? 'Atualize os dados bancários para voltar a utilizar o serviço.'
          : 'Entre em contato com o responsável para atualizar os dados bancários para e voltar a utilizar o serviço'
      } `,
      status: 'error',
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    });
  }
  function showToast() {
    toast({
      position: 'bottom',

      duration: 30000,
      isClosable: true,
      render: () => (
        <Alert
          status="error"
          variant="solid"
          flexDirection="column"
          onClick={() => {
            console.log('click');
          }}
          rounded="md"
        >
          <HStack w="100%">
            <AlertIcon />
            <AlertTitle w="80%" align="center">
              Importante
            </AlertTitle>
            <CloseButton alignSelf="flex-end" position="relative" right={-1} top={-1} onClick={() => toast.closeAll()} />
          </HStack>
          <AlertDescription
            maxWidth="sm"
            textAlign="justify"
            onClick={() => {
              toast.closeAll();
              navigate('/validacaobird');
            }}
          >
            Apenas usuários com certificado digital podem prescrever receitas. Clique aqui para validar seu certificado digital.
          </AlertDescription>
        </Alert>
      ),
      containerStyle: {
        paddingBottom: '100px',
      },
    });
  }

  return (
    <>
      <ModalReenvioMsg
        disclosure={reenvioMsg}
        paciente_data={{ ...user.cart, paciente: state.paciente, empresa_id: user.empresa_id, operador_id: user.operador_id, token: user.tokenBird }}
      />
      <Drawer placement={'bottom'} isOpen={disclosure.isOpen} onClose={disclosure.onClose} mt="10%">
        <DrawerContent h={'45%'}>
          <DrawerBody backgroundColor="#95F3EE" borderTopRadius="2em" pl={5} pr={5}>
            <VStack w="100%">
              <Heading textAlign="center" fontSize="1.2em" m="5% 0">
                O que deseja solicitar?
              </Heading>

              <HStack mt="10%" spacing={2} w={'100%'} display={'flex'}>
                {type === 'EXAME' || !type ? (
                  <Button
                    bgColor="white"
                    w="100%"
                    h="6em"
                    borderRadius={'1em'}
                    onClick={() => {
                      if (user.empresa_valida !== true) {
                        callToast();
                        return;
                      }
                      navigate('/solicitacao/exame', { state: { tipo: 'EXAME', paciente: state.paciente } });
                    }}
                  >
                    <Flex w="100%" direction="column" lineHeight="100%">
                      <Img src={Solicitar_exames} w="2em" mb="0.5em" style={{ margin: 'auto' }} />
                      <Text fontSize="0.8em" textAlign="center" mt={'1em'}>
                        Solicitar
                      </Text>
                      <Text fontSize="0.8em" textAlign="center">
                        exames
                      </Text>
                    </Flex>
                  </Button>
                ) : (
                  <></>
                )}
                {type === 'PRESCRICAO' || !type ? (
                  <Button
                    bgColor="white"
                    w="100%"
                    h="6em"
                    borderRadius={'1em'}
                    onClick={() => {
                      if (user.empresa_valida !== true) {
                        callToast();
                      } else if (user.tokenBird === null || !user.crm) {
                        showToast();
                      } else {
                        navigate('/solicitacao/prescricao', { state: { tipo: 'EXAME', paciente: state.paciente } });
                      }
                    }}
                  >
                    <Flex w="100%" direction="column" lineHeight="100%">
                      <Img src={Prescrever_medicamentos} w="2em" mb="0.5em" style={{ margin: 'auto' }} />
                      <Text fontSize="0.8em" textAlign="center" mt={'1em'}>
                        Prescrever
                      </Text>
                      <Text fontSize="0.8em" textAlign="center">
                        médicamentos
                      </Text>
                    </Flex>
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  bgColor="white"
                  w="100%"
                  borderRadius={'1em'}
                  h="6em"
                  onClick={() => {
                    if (user.empresa_valida !== true) {
                      callToast()
                    } else if (user.tokenBird === null || !user.crm) {
                      showToast();
                    } else {
                    navigate('/solicitacao/atestado', { state: { tipo: 'EXAME', paciente: state.paciente } });
                    }
                  }}
                >
                  <Flex w="100%" direction="column" lineHeight="100%">
                    <Img src={Prescrever_atestado} w="1.5em" mb="0.5em" style={{ margin: 'auto' }} />
                    <Text fontSize="0.8em" textAlign="center" mt={'1em'}>
                      Prescrever
                    </Text>
                    <Text fontSize="0.8em" textAlign="center">
                      atestado
                    </Text>
                  </Flex>
                </Button>
              </HStack>

              <HStack mt="10%" spacing={2} w={'100%'} display={'flex'}>
                <Button
                  bgColor="white"
                  w="100%"
                  h="6em"
                  borderRadius={'1em'}
                  onClick={() => {
                    if (user.empresa_valida !== true) {
                      callToast()
                    } else if (user.tokenBird === null || !user.crm) {
                      showToast();
                    } else {
                    navigate('/solicitacao/area-livre', { state: { tipo: 'EXAME', paciente: state.paciente } });
                    }
                  }}
                >
                  <Flex w="100%" direction="column" lineHeight="100%">
                    <Img src={AreaLivre} w="1.5em" mb="0.5em" style={{ margin: 'auto' }} />
                    <Text fontSize="0.8em" textAlign="center" mt={'1em'}>
                      Área livre
                    </Text>
                  </Flex>
                </Button>

                <Button
                  width="100vh"
                  bgColor="white"
                  w="100%"
                  borderRadius={'1em'}
                  h="6em"
                  onClick={() => {
                    navigate('/consulta', { state: { tipo: 'EXAME', paciente: state.paciente } });
                  }}
                >
                  <Flex w="100%" direction="column" lineHeight="100%">
                    <Img src={Prescrever_atestado} w="1.5em" mb="0.5em" style={{ margin: 'auto' }} />
                    <Text fontSize="0.8em" textAlign="center" mt={'1em'}>
                      Encaminhar
                    </Text>
                    <Text fontSize="0.8em" textAlign="center">
                      Paciente
                    </Text>
                  </Flex>
                </Button>
              </HStack>
              {user.cart && (
                <ButtonGroup pt="5%" w="100%" pb="5%">
                  <Flex flexDirection={'column'} w="100%" align="center">
                    <Button
                      colorScheme="teal"
                      w="60%"
                      h="3em"
                      borderRadius="2em"
                      onClick={() => {
                        if (user.empresa_valida !== true) {
                          callToast();
                        } else {
                          fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/request`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                              credentials: 'include',
                            },
                            body: JSON.stringify({
                              ...user.cart,
                              paciente: state.paciente,
                              empresa_id: user.empresa_id,
                              operador_id: user.operador_id,
                              token: user.tokenBird,
                            }),
                          });
                          reenvioMsg.onOpen();
                          //navigate('/alerts/sucesso/default');
                        }
                      }}
                    >
                      Concluir
                    </Button>
                  </Flex>
                </ButtonGroup>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ActionChoice;
