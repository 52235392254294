import React, { useContext, useState } from 'react';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import { AccountContext } from '../AccountContext';
import { Formik, Form } from 'formik';
import { Button, ButtonGroup, Image, InputGroup, InputRightElement, VStack, chakra, useToast } from '@chakra-ui/react';
import TextField from '../Utils/Inputs/TextField';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import voltar from '../../assets/images/v.svg';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
function Novasenha() {
  const toast = useToast();
  const { setUser, setPage } = useContext(AccountContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  setPage('Nova Senha');
  const handleClick = () => setShow(!show);
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }
  return (
    <>
      <VStack align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
        <Image mr="18em" src={voltar} onClick={() => navigate(-1)} size="lg"></Image>{' '}
        <Formik
          initialValues={{}}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            senha1: Yup.string()
              .required('Senha é obrigatória!')
              .min(6, 'A senha deve ter no mínimo 6 caracteres'),
            senha2: Yup.string()
              .required('Confirmação da senha é obrigatória')
              .min(6, 'A senha de confirmação deve ter no mínimo 6 caracteres')
              .test('pass-match', 'Senhas não correspondem. Por favor, digite novamente', function (value) {
                return this.parent.senha1 === value;
              }),
          })}
          onSubmit={(values, actions) => {
            let senha = values.senha1;
            let cpf = state.cpf;
            let operador_id = state?.operador_id;
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/atualizarsenha/${senha}/${cpf}/${operador_id}`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .catch((err) => {
                return;
              })
              .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                  return;
                }
                return res.json();
              })
              .then((data) => {
                if (!data) return;
                if (data && data.message === 'validated') {
                  callToast("Importante", 'A senha que está tentando cadastrar é igual a anterior. Cadastre uma que não foi cadastrada anteriormente', 'error')
                  return
                }
                navigate('/senhaalteradafinal');
              });
          }}
        >
          <VStack as={Form} w="80%" pt="20%">
            <chakra.p color="#318681" fontSize={'1em'} fontWeight={'bold'} paddingBottom="2em">
              Agora, é só digitar sua nova senha
            </chakra.p>
            <chakra.h1 fontSize="0.8em" color="#318681" fontWeight="bold">
              Nova senha
            </chakra.h1>
            <InputGroup>
              <TextField
                name="senha1"
                placeholder="Digite sua nova senha"
                textAlign="left"
                fontSize="0.7em"
                color="#D6D6D6"
                backgroundColor="#FFFFFF"
                maskChar={null}
                type={show ? 'text' : 'password'}
                borderRadius="0.6em"
                h="3.9em"
                fontWeight="bold"
                autoComplete="off"
              />
              <InputRightElement mt="3%">
                <Button size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
              </InputRightElement>
            </InputGroup>
            <chakra.h1 fontSize="0.8em" color="#318681" fontWeight="bold">
              Repita a senha
            </chakra.h1>

            <InputGroup>
              <TextField
                name="senha2"
                placeholder="Digite sua nova senha"
                textAlign="left"
                fontSize="0.7em"
                color="#D6D6D6"
                backgroundColor="#FFFFFF"
                maskChar={null}
                type={show ? 'text' : 'password'}
                borderRadius="0.6em"
                h="3.9em"
                fontWeight="bold"
                autoComplete="off"
              />
              <InputRightElement mt="3%">
                <Button size="sm" onClick={handleClick} leftIcon={show ? <ViewIcon /> : <ViewOffIcon />}></Button>
              </InputRightElement>
            </InputGroup>
            <ButtonGroup pt={{ md: '15em', sm: '35%' }} sx={{ width: '100%' }}>
              <Button
                type="submit"
                w={{
                  base: '100%',
                }}
                backgroundColor="#5AB2AD"
                color="#FFFFFF"
                borderRadius="0.6em"
                h="2.9em"
              >
                Continuar
              </Button>
            </ButtonGroup>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default Novasenha;
