import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    Stack,
    DrawerOverlay,
    Image,
    Button,
    useDisclosure,
    VStack,
    Center,
    Header,
    Heading,
    HStack,
    Text,
    Divider,
    TableContainer,
    Table,
    Tr,
    Td,
    ButtonGroup,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Badge,
    Input,
    useToast,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AccountContext } from '../../AccountContext';

const LinkButton = ({ link, type }) => {
    const { user } = useContext(AccountContext);

    const fileName = String(link).split('/').pop().split('?')[0];

    return (
        <Button
            w={'100%'}
            backgroundColor={'orange'}
            color={'#FFF'}
            onClick={() => {
                window.open(link, '_blank');
            }}
        >
            {`${type} ${fileName}`}
        </Button>
    );
};

function DrawerHistorico(props) {
    const disclosure = props.disclosure;
    const documentos = props.documentos;
    const ReceituariosSimplesAssinados = () => {
        if ('receituario_simples_assinado' in documentos && documentos.receituario_simples_assinado.length > 0) {
            return (
                <>
                    {documentos.receituario_simples_assinado.map((e, id) => (
                        <LinkButton link={e} type={'Receituário'} />
                    ))}
                </>
            );
        }
        // else {
        //     return (
        //         <Center>
        //             <Text>Não há receituários disponíveis</Text>
        //         </Center>
        //     );
        // }
    };

    const AtestadosAssinados = () => {
        if ('Atestado_assinado' in documentos && documentos.Atestado_assinado.length > 0) {
            return (
                <>
                    {documentos.Atestado_assinado.map((e, id) => (
                        <LinkButton link={e} type={'Atestado'} />
                    ))}
                </>
            );
        }
        // else {
        //     return (
        //         <Center>
        //             <Text>Não há atestados disponíveis</Text>
        //         </Center>
        //     );
        // }
    };

    const ReceituarioEspecialAssinado = () => {
        if ('receituario_especial_assinado' in documentos && documentos.receituario_especial_assinado.length > 0) {
            return (
                <>
                    {documentos.receituario_especial_assinado.map((e, id) => (
                        <LinkButton link={e} type={'Especial'} />
                    ))}
                </>
            );
        }
        // else {
        //     return (
        //         <Center>
        //             <LinkButton>Não há Receituarios especiais disponíveis</Text>
        //         </Center>
        //     );
        // }
    };

    return (
        <>

            <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
                <DrawerOverlay />
                <DrawerContent borderRadius={20} mb="-10" width="100%" h="70%">
                    <DrawerBody mt="0" p="5%">
                        <Stack direction="column" spacing={3} align="center" w="100%">
                            <VStack w="100%">
                                <Tabs w="100%">
                                    <TabList>
                                        <Tab w="50%">Receituários</Tab>
                                        <Tab w="50%">Atestados</Tab>
                                    </TabList>
                                    <TabPanels>
                                        {/*primeira aba*/}
                                        <TabPanel>
                                            <TableContainer w="100%">
                                                <VStack mt="5%">
                                                    <ReceituariosSimplesAssinados />
                                                    <ReceituarioEspecialAssinado />
                                                </VStack>
                                            </TableContainer>
                                        </TabPanel>

                                        {/*segunda aba*/}
                                        <TabPanel>
                                            <TableContainer w="100%">
                                                <VStack mt="5%">
                                                    <AtestadosAssinados />
                                                </VStack>
                                            </TableContainer>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </VStack>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default DrawerHistorico