import {
    Center,
    Heading,
    chakra,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    Button,
    ModalFooter,
    Text,
    VStack,
    ButtonGroup,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountContext } from '../../../AccountContext';
import { CheckCircleIcon } from '@chakra-ui/icons';

function ModalReenvioMsg(props) {
    const disclosure = props.disclosure;
    const { setUser, user } = useContext(AccountContext);
    const navigate = useNavigate();
    const paciente_data = props.paciente_data
    console.log('paciente_data ', paciente_data)
    const [counter, setCounter] = useState(30);
    const [showText, setShowText] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    function reSendMessage() {
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/reSendrequest`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                credentials: 'include',
            },
            body: JSON.stringify({ paciente_data }),
        });
    }
    const contar = () => {
        let aux = 30;
        var countdown = setInterval(function () {
            aux--;
            setCounter(aux);
            if (aux === 0) {
                clearInterval(countdown);
                setShowText(false);
                setDisableButton((disableButton) => false);
                setCounter(30);
            }
        }, 1000);
    };
    return (
        <>
            <Modal isOpen={disclosure.isOpen} isCentered onClose={disclosure.onClose}>
                <ModalOverlay />
                <ModalContent maxW="90%" borderRadius="2EM">
                    <ModalBody>
                        <Center>
                            <Heading textAlign="center" color="red" mt="5" size="md">
                                <CheckCircleIcon mb="5" color="green" boxSize={20} />
                            </Heading>
                        </Center>
                        <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="1.2em">
                            Sua solicitação foi enviada com sucesso! Deseja finalizar a solicitação ou reenviar a solicitação para o paciente?
                        </chakra.h4>
                    </ModalBody>
                    <VStack mb={'2em'}>
                        <Button backgroundColor="teal" color="white" h="3em" w="15em" mt="0.8em" fontWeight={'bold'} type="submit" borderRadius="2em" onClick={() => { navigate('/alerts/sucesso/default'); }}>
                            Finalizar
                        </Button>
                        <Button
                            colorScheme='teal'
                            variant='link'
                            h="3em"
                            w="15em"
                            mt="0.8em"
                            fontWeight="bold"
                            type="submit"
                            borderRadius="2em"
                            onClick={() => {
                                setDisableButton(true);
                                reSendMessage();
                                setShowText(true);
                                contar();
                            }}
                            disabled={disableButton}
                            isLoading={disableButton}
                            loadingText={'Aguarde' + ' ' + counter + ' ' + 'segundos'}
                            opacity={disableButton ? 1 : 1}
                            pointerEvents={disableButton ? 'none' : 'auto'}
                        >
                            Reenviar solicitação
                        </Button>
                    </VStack>
                </ModalContent>
            </Modal >
        </>
    )
}

export default ModalReenvioMsg