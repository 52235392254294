import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Stack,
  DrawerOverlay,
  Image,
  Button,
  useDisclosure,
  VStack,
  Center,
  Header,
  Heading,
  HStack,
  Text,
  Divider,
  TableContainer,
  Table,
  Tr,
  Td,
  ButtonGroup,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  Input,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../AccountContext';

function DrawerSaque(props) {
  const disclosure = props.disclosure;
  const recipientBalance = {
    available: {
      amount: props.disponivel * 100,
    },
    waiting_funds: {
      amount: props.antecipar * 100,
    },
  };

  const bankData = props.bankData;
  const [saque, setSaque] = useState(0);
  const [limits, setLimits] = useState(0);
  const [antecipar, setAntecipar] = useState(0);
  const { user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const vals = {
    medico: user.perfil_id === 4 ? true : false,
    empresa_id: user.empresa_id,
    operador_id: user.operador_id,
    recipient_id: user.recipient_id,
  };

  async function fetchData(url) {
    try {
      let response = await fetch(url, {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
      const postsData = await response.json();

      setEmpresa(postsData?.data);
    } catch (error) { }
  }

  async function fetchLimits() {
    try {
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/getValoresMinMax/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(vals),
      })
        .then(async (response) => {
          return response.json();
        })
        .then(async (resposta_data) => {
          setLimits(resposta_data.data);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/selectEmpresarById/${user.empresa_id}`);
    fetchLimits();
  }, []);

  const saldoRestante = ((props?.disponivel * 100 - saque - 367) / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const saldoRestanteReceber = (
    (props?.antecipar * 100 - antecipar - (limits?.data?.minimum ? limits?.data?.minimum.anticipation_fee + limits?.data?.minimum.fee : 365)) /
    100
  ).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'bottom',
      colorScheme: 'red',
      containerStyle: {
        paddingBottom: '50px',
      },
    });
  }

  let recipient_id = '';

  const payload = {
    medico: user.perfil_id === 4 ? true : false,
    id_tipo_movimentacao: 1,
    vlr_total: recipientBalance?.waiting_funds?.amount / 100,
    vlr_transferido: (recipientBalance?.waiting_funds?.amount - antecipar - 5) / 100,
    id_empresa: user.empresa_id,
    id_operador: user.operador_id,
    hash: null,
  };
  const transfer = async (amount) => {
    if (amount / 100 > props.disponivel) {
      callToast('Importante', 'Você não pode sacar um valor maior do que o saldo disponível', 'error');
      props.update();
      disclosure.onClose();
    } else {
      await fetch(
        `${process.env.REACT_APP_BASE_URL_API_SERVER}/payment/transfer/${user.recipient_id}/${amount}/${payload.medico}/1/${payload.vlr_total}/${payload.vlr_transferido}/${payload.id_empresa}/${payload.id_operador}/${payload.hash}
      `,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
          },
        }
      )
        .then(async (resposta) => {
          return resposta.json();
        })
        .then(async (data) => {
          if (data.success == true) {
            callToast('Sucesso', 'Saque realizado com sucesso', 'success');
            props.update();
            disclosure.onClose();
          } else {
            if (data.success == false) {
              callToast('Importante', 'Não foi possível realizar o saque', 'error');
              props.update();
              disclosure.onClose();
            }
          }
        });
    }
  };

  const antecipation = async (amount) => {
    if (amount / 100 > props.antecipar) {
      callToast('Importante', 'Você não pode antecipar um valor maior do que o saldo disponível para antecipação', 'error');
      props.update();
      disclosure.onClose();
    } else {
      await fetch(
        `${process.env.REACT_APP_BASE_URL_API_SERVER}/payment/antecipation/${user.recipient_id}/${amount}/${payload.medico}/2/${payload.vlr_total}/${payload.vlr_transferido}/${payload.id_empresa}/${payload.id_operador}/${payload.hash}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            credentials: 'include',
          },
        }
      )
        .then(async (resposta) => {
          return resposta.json();
        })
        .then(async (data) => {
          if (data.success == true) {
            callToast('Sucesso', 'Antecipação realizada com sucesso', 'success');
            props.update();
            disclosure.onClose();
          } else {
            if (data.success == false) {
              callToast('Importante', 'Não foi possível realizar a antecipação', 'error');
              props.update();
              disclosure.onClose();
            }
          }
        });
    }
  };
  const maskMoneyInput = (e) => {
    let maskValue = e.target.value;
    maskValue = maskValue.replace(/\D/g, '');
    maskValue = maskValue.replace(/(\d{1,2})$/, ',$1');
    maskValue = maskValue.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.target.value = maskValue;
  };
  const [empresa, setEmpresa] = useState([]);

  const bankAccountTypesPagarme = ['conta_corrente', 'conta_poupanca', 'conta_corrente_conjunta', 'conta_poupanca_conjunta'];
  const bankAccountTypes = ['Conta corrente', 'Conta poupança', 'Conta corrente conjunta', 'Conta poupança conjunta'];

  const indiceCorrespondente = bankAccountTypesPagarme.indexOf(empresa[0]?.tipoconta);

  let tipo_conta = '';
  if (indiceCorrespondente !== -1) {
    tipo_conta = bankAccountTypes[indiceCorrespondente];
  } else {
    tipo_conta = 'Conta não encontrada';
  }

  return (
    <>
      <Drawer isOpen={disclosure.isOpen} onClose={disclosure.onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent borderRadius={20} mb="-10" width="100%" h="70%">
          <DrawerBody mt="10" p="5%">
            <Stack direction="column" spacing={3} align="center" w="100%">
              <HStack w="100%">
                <Text>Dados:</Text>
                <VStack w="100%">
                  <Text>Agência</Text>
                  <Text> {empresa[0]?.numagencia ? empresa[0]?.numagencia : ''}</Text>
                </VStack>

                <VStack w="100%">
                  <Text>Conta</Text>
                  <Text>{empresa[0]?.numconta ? empresa[0]?.numconta : ''}</Text>
                </VStack>
              </HStack>

              <VStack w="100%">
                <Tabs w="100%">
                  <TabList>
                    <Tab w="100%">DISPONÍVEL PARA SAQUE</Tab>
                    {/*<Tab w="50%">A RECEBER</Tab>*/}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <TableContainer w="100%">
                        <VStack>{error ? <Text color="red">{error}</Text> : <></>}</VStack>

                        <Center mt="5%">
                          <Badge ml="0.5em" borderRadius="10px" w="40%" backgroundColor="#1FAB8A" h="2.6em">
                            <Center h="2.5em" color="white">
                              {' '}
                              {(recipientBalance?.available?.amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Center>
                          </Badge>
                        </Center>
                        <VStack w="100%" mt="5%">
                          <Text>Valor desejado(R$)</Text>
                          <Input
                            w="80%"
                            type="text"
                            name="saque"
                            borderRadius="10px"
                            textAlign="center"
                            onChange={(e) => {
                              maskMoneyInput(e);
                              setSaque(
                                parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100 > 0
                                  ? parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100
                                  : 0
                              );
                            }}
                          />
                        </VStack>
                        <VStack w="70%" mt="5%" spacing={-1}>
                          <Text fontSize="10px" align="right" w="100%">
                            Valor mínimo para saque: R$ 3,67
                          </Text>
                          <Text fontSize="10px" align="right" w="100%">
                            Taxa por saque: R$ 3,67
                          </Text>
                        </VStack>
                        <Table mt="5%">
                          <Td textAlign="right" w="30%">
                            <Tr>Saldo Disponível:</Tr>
                            <Tr color="#1FAB8A">Saque:</Tr>
                            <Tr color="#FF003D">Taxa:</Tr>
                          </Td>
                          <Td>
                            <Tr>{props?.disponivel}</Tr>
                            <Tr color="#1FAB8A">
                              {(saque / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#FF003D">R$ 3,67</Tr>
                          </Td>
                          <Divider></Divider>
                          <Tr w="100%" textAlign="right">
                            <Td textAlign="right">Saldo restante</Td>
                            <Td color={props?.disponivel * 100 - saque - 367 < 0 ? 'red' : 'black'}>{saldoRestante}</Td>
                          </Tr>
                        </Table>
                        <ButtonGroup mb="10%" w="100%">
                          <Button
                            w="100%"
                            colorScheme="teal"
                            // isLoading={disabled}
                            // loadingText="Saque em processamento, aguarde..."
                            onClick={() => {
                              if (saque > 0) {
                                setError(null);
                                setDisabled(true);
                                transfer(saque);
                              } else {
                                setError('Valor inválido');
                              }
                            }}
                          >
                            Sacar
                          </Button>
                        </ButtonGroup>
                      </TableContainer>
                    </TabPanel>
                    {/*<TabPanel>
                      <TableContainer w="100%">
                        <VStack>{error ? <Text color="red">{error}</Text> : <></>}</VStack>

                        <Center mt="5%">
                          <Badge ml="0.5em" borderRadius="10px" w="40%" backgroundColor="#1FAB8A" h="2.6em">
                            <Center h="2.5em" color="white">
                              {' '}
                              {(recipientBalance?.waiting_funds?.amount / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Center>
                          </Badge>
                        </Center>
                        <VStack w="100%" mt="5%">
                          <Text>Antecipar(R$)</Text>
                          <Input
                            w="80%"
                            type="text"
                            id="antecipar"
                            borderRadius="10px"
                            name="antecipar"
                            textAlign="center"
                            onChange={(e) => {
                              maskMoneyInput(e);
                              setAntecipar(
                                parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100 > 0
                                  ? parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')) * 100
                                  : 0
                              );
                            }}
                          />
                        </VStack>
                        <VStack w="100%" mt="5%" spacing={-1}>
                          <Text fontSize="10px" align="center " w="100%">
                            Esta antecipação será analisada e poderá ser aprovada ou rejeitada.
                          </Text>
                          <Text fontSize="10px" align="center" w="100%">
                            Após a aprovação, o valor a ser antecipado é adicionado ao saldo a
                          </Text>
                          <Text fontSize="10px" align="center" w="100%">
                            {' '}
                            em até 2 dias úteis
                          </Text>
                        </VStack>
                        <Table mt="5%">
                          <Td textAlign="right" w="30%">
                            <Tr>Saldo Disponível:</Tr>
                            <Tr color="#1FAB8A">Saque:</Tr>
                            <Tr color="#FF003D">Taxa:</Tr>
                          </Td>
                          <Td>
                            <Tr>{props.antecipar}</Tr>
                            <Tr color="#1FAB8A">
                              {(antecipar / 100).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </Tr>
                            <Tr color="#FF003D">
                              R$ {limits?.data?.minimum ? (limits?.data?.minimum.anticipation_fee + limits?.data?.minimum.fee) / 100 : 3.65}
                            </Tr>
                          </Td>
                          <Divider></Divider>
                          <Tr w="100%" textAlign="right">
                            <Td textAlign="right">Saldo restante</Td>
                            <Td
                              color={
                                recipientBalance?.waiting_funds?.amount -
                                  antecipar -
                                  (limits?.data?.minimum ? limits?.data?.minimum.anticipation_fee + limits?.data?.minimum.fee : 365) <
                                0
                                  ? 'red'
                                  : 'black'
                              }
                            >
                              {saldoRestanteReceber}
                            </Td>
                          </Tr>
                        </Table>
                        <ButtonGroup mb="10%" w="100%">
                          <Button
                            w="100%"
                            colorScheme="teal"
                            // isLoading={disabled}
                            // loadingText="Antecipação em processamento, aguarde..."
                            onClick={() => {
                              if (antecipar > 0) {
                                setError(null);
                                setDisabled(true);
                                antecipation(antecipar);
                              } else {
                                setError('Valor inválido');
                              }
                            }}
                          >
                            Antecipar
                          </Button>
                        </ButtonGroup>
                      </TableContainer>
                    </TabPanel>*/}
                  </TabPanels>
                </Tabs>
              </VStack>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerSaque;
