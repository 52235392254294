import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Img,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  chakra,
  Tabs,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
  ModalFooter,
  Stack,
  useToast,
  InputGroup,
  InputRightElement,
  Tbody,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../../AccountContext';
import Loading from '../../../Utils/Loading';
import DoctorHeader from '../../../Utils/Headers/DoctorHeader';
import Heart from '../../../../assets/images/icons/actions/heart.svg';
import TodosExame from '../../../../assets/images/categorias/todos/todos_exame.svg';
import TodosOdonto from '../../../../assets/images/categorias/todos/todos_odonto.svg';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { MdAddCircleOutline } from 'react-icons/md';
import ExameAction from '../../../Utils/Drawer/ExameAction';
import ComboModal from '../../../Utils/Modal/Exame/ComboModal';
import { CheckCircleIcon, Search2Icon, SmallCloseIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Form, Formik } from 'formik';

function Procedimentos() {
  const { user, setUser, setPage } = useContext(AccountContext);
  const { state } = useLocation();
  setPage('Exame');
  const [combos, setCombos] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [subgroup, setSubgroup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingChild, setLoadingChild] = useState(true);
  const [otherProcedures, setOthers] = useState([]);
  const [textSearch, setTextSearch] = useState(null);

  const [pag, setPag] = useState(0);
  const [cart, setCart] = useState(user.cart && user.cart.itens ? user.cart.itens : []);
  const [comboPick, setComboPick] = useState();

  const toast = useToast({
    title: 'Aviso',
    description: 'Não foi possível completar sua solicitação no momento, tente novamente mais tarde',
    status: 'error',
    duration: 4000,
    isClosable: true,
    position: 'top',
    colorScheme: 'red',
    containerStyle: {
      paddingTop: '50px',
    },
  });

  const actionDisclosure = useDisclosure();
  const modalDisclosure = useDisclosure();
  const comboModalDisclosure = useDisclosure();
  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (_.isEqual(list[i], obj)) {
        return true;
      }
    }

    return false;
  };
  const handleCombo = (combo) => {
    console.log(combo);
    setCart([...cart, ...combo]);
  };


  useEffect(() => {
    if (cart.length) {
      setUser((prevState) => ({
        ...prevState,
        cart: {
          ...prevState.cart,
          itens: cart,
          prescricao: prevState.cart?.prescricao ? [...prevState.cart.prescricao] : []
        },
      }));
    }

  }, [cart]);



  useEffect(() => {
    if (user.cart?.itens && user.cart.itens && user.cart.itens.length < cart.length) {
      console.log('setCart([...cart, item] 111);')
      setCart(user.cart.itens);
    }
    if (user.cart?.itens === null) {
      setCart([]);
    }
  }, [user.cart]);

  const useSubgroup = subgroup.map((item, index) => {
    return item.subgrupo_nome !== 'OUTROS' ? (
      <Td id={`td${index}`} borderRadius="15px" p="0.5em" fontSize="xl" textAlign="center" class="margin-td">
        <HStack
          id={`date${index}`}
          backgroundColor="#F0F0F0"
          shadow="base"
          borderRadius="15px"
          height="5em"
          w="100%"
          style={pag === index ? { border: '1px solid #5AB2ADB3' } : {}}
          onClick={() => {
            setPag(index);
          }}
        >
          <Box w="100%" borderRadius="5px" color="rgba(62, 100, 255, 1)">
            <Center w="100%">
              <Image
                name={item ? item.subgrupo_nome : ''}
                src={
                  item
                    ? item.subgrupo_nome === 'TODOS'
                      ? state.tipo === 'EXAME'
                        ? TodosExame
                        : TodosOdonto
                      : `${process.env.REACT_APP_URL_SUBGRUPO_PROD}/` + item.subgrupo_id + '.png'
                    : ''
                }
                fallbackSrc={`${process.env.REACT_APP_URL_LOGOMARCA}/logomarca.png`}
              />
            </Center>
          </Box>
        </HStack>
        <Text fontSize="0.4em" textAlign="center" color="#808080">
          {item ? item.subgrupo_nome : ''}
        </Text>
      </Td>
    ) : (
      <></>
    );
  });

  const useProcedures = procedures.map((item, index) => {
    return item && subgroup[pag] ? (
      (subgroup[pag].subgrupo_nome === 'TODOS' && cart.indexOf(item) === -1 && containsObject(item, cart) === false) ||
        (item.subgrupo_nome === subgroup[pag].subgrupo_nome && cart.indexOf(item) === -1 && containsObject(item, cart) === false) ? (
        <Tr h="5em">
          <Flex borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0">
            <VStack ml="1em" align="start" w="100%" mt="1em">
              <Text noOfLines={4} minH={55} color="#585858" fontWeight="bold" fontSize="0.8em">
                {item.nome}
              </Text>
            </VStack>
            <VStack w={20} bgColor="#5AB2AD" borderRightRadius="10px">
              <Center h={100}>
                <MdAddCircleOutline
                  size="2em"
                  color="white"
                  onClick={() => {
                    setCart([...cart, item]);
                    setUser((prevState) => ({
                      ...prevState,
                      cart: {
                        ...prevState.cart,
                        itens: [...prevState.cart?.itens ?? [], item],
                        prescricao: prevState.cart?.prescricao ? [...prevState.cart.prescricao] : []
                      },
                    }));
                  }}
                />
              </Center>
            </VStack>
          </Flex>
        </Tr>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  });
  const useProceduresWithoutSubgroup = procedures.map((item, index) => {
    return item && cart.indexOf(item) === -1 && containsObject(item, cart) === false ? (
      <Tr h="5em">
        <Flex borderRadius="10px" shadow="md" mt="1em" width="95%" backgroundColor="#F0F0F0">
          <VStack ml="1em" align="start" w="100%">
            <Text noOfLines={3} minH={55}>
              {item.nome}
            </Text>
          </VStack>
          <VStack w={20} bgColor="#5AB2AD" borderRightRadius="10px">
            <Center h={100}>
              <MdAddCircleOutline
                size="2em"
                color="white"
                onClick={() => {
                  setCart([...cart, item]);
                }}
              />
            </Center>
          </VStack>
        </Flex>
      </Tr>
    ) : (
      <></>
    );
  });

  const getCombos = () => {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/combos`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch((err) => {
        return;
      })
      .then((res) => {
        if (!res || !res.ok || res.status >= 400) {
          return;
        }
        return res.json();
      })
      .then((data) => {
        if (!data) return;
        setLoading(false);
        setCombos(data);
      });
  };

  const getProcedures = async () => {
    const url = process.env.REACT_APP_BASE_URL_API_SERVER + '/schedule/getProcedures/EXAME';
    const response = await fetch(url, {
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      method: 'GET',
    });
    const postsData = await response.json();
    setProcedures(postsData);
    let uniqueSubgroups;

    uniqueSubgroups = _.uniqBy(postsData, 'subgrupo_nome');

    uniqueSubgroups.unshift({ subgrupo_nome: 'TODOS', grupo: 'TODOS' });
    const index = uniqueSubgroups.findIndex((subgroup) => subgroup.subgrupo_nome === 'LABORATORIAL');
    if (index !== -1) {
      const subgroup = uniqueSubgroups.splice(index, 1)[0];
      uniqueSubgroups.splice(1, 0, subgroup);
    }
    setLoading(false);
    setSubgroup(uniqueSubgroups);
  };

  const search = async (text) => {
    setLoadingChild(true);
    setTextSearch(text);
    if (text === '' || text === null) {
      await getProcedures();
      return;
    }
    try {
      const url = `${process.env.REACT_APP_BASE_URL_API_SERVER}/schedule/searchProcedures?text=${text}&group=0&tipo=${state.tipo}`;
      const responseData = await fetch(url);
      const response = await responseData.json();
      if (response.success) {
        const proceduresData = response.data;
        setProcedures([]);
        setOthers([]);
        setProcedures(proceduresData);
      } else {
        toast();
      }
    } catch (err) {
      toast();
    }
  };
  useEffect(() => {
    setLoading(true);
    getCombos();
    getProcedures();
  }, []);
  const useCombo = combos.map((combo) => (
    <Tr
      h="7em"
      onClick={() => {
        setComboPick(combo);
        comboModalDisclosure.onOpen();
      }}
    >
      <Flex
        borderRadius="10px"
        shadow="md"
        mt="1em"
        width="100%"  // Occupy the full width
        backgroundColor="#F0F0F0"
        alignItems="center"
        justifyContent="space-between"
      >
        <VStack w={20} borderRightRadius="10px">
          <Center h={100}>
            <Image
              src={`${process.env.REACT_APP_URL_SUBGRUPO_PROD}/logomarca.png`}
              fallbackSrc={Heart}
              size="2em"
              color="white"
              padding={'10px'}
              name={combo.nome}
            />
          </Center>
        </VStack>
        <VStack ml="1em" mt="0.5em" align="center" w="70%">
          <Text noOfLines={4} minH={55} color="#585858" fontWeight="bold" fontSize="0.8em" mt={'2em'} mr={'1em'}>
            {combo && combo.nome ? combo.nome.toUpperCase() : ''}
          </Text>
        </VStack>
      </Flex>
    </Tr>
  ));

  return (
    <>
      <DoctorHeader cart={cart} />
      <ComboModal disclosure={comboModalDisclosure} combo={comboPick} change={handleCombo} />
      <Modal isOpen={modalDisclosure.isOpen} isCentered onClose={modalDisclosure.onClose}>
        <ModalOverlay />
        <ModalContent maxW="85%" borderRadius="2EM">
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Heading textAlign="center" color="#808080" mt="5" size="md">
                Deseja solicitar outros exames?{' '}
              </Heading>
            </Center>
            <chakra.h4 mt="0.2em" color="#808080" textAlign="center" fontSize="0.8em">
              Antes de prosseguir, adicione todos os exames que deseja solicitar.
            </chakra.h4>
          </ModalBody>
          <Center>
            <Stack direction="column" spacing={4} align="center">
              <Button
                bgColor="teal"
                mt="5%"
                _hover="none"
                color="white"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                autofocus="true"
                onClick={() => {
                  modalDisclosure.onClose();
                }}
              >
                Adicionar mais procedimentos
              </Button>
              <Button
                bgColor="#5AB2AD"
                color="white"
                _hover="none"
                w="88%"
                size="lg"
                borderRadius="0.6em"
                onClick={() => {
                  modalDisclosure.onClose();
                  actionDisclosure.onOpen();
                }}
              >
                Já selecionei todos
              </Button>
            </Stack>
          </Center>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <ExameAction type={'PRESCRICAO'} disclosure={actionDisclosure} />
      {loading ? (
        <>
          <VStack w="100%" mt="-20%">
            <Loading />
          </VStack>
        </>) : (<>
          <Formik initialValues={{ searchBar: '' }} validateOnChange={false} validateOnBlur={false}>
            {(propsFormik) => {
              const { handleChange, values } = propsFormik;
              return (
                <VStack w="100%" mt="5%" as={Form}>
                  <InputGroup w="90%">
                    <Input
                      name="searchBar"
                      textAlign="left"
                      placeholder="Buscar"
                      color="#939598"
                      backgroundColor="#EFEFEF"
                      mask="999.999.999-99"
                      maskChar={null}
                      type="text"
                      autoComplete="off"
                      w="100%"
                      onChange={handleChange}
                      onKeyPress={(event) => {
                        let textSearch = event.target.value;

                        if (event.key === 'Enter') {
                          search(textSearch);
                        }
                      }}
                    />
                    <InputRightElement>
                      <Button
                        width="100%"
                        variant="link"
                        children={<Search2Icon color="#939598" />}
                        onClick={() => {
                          let textSearch = values.searchBar;
                          search(textSearch);
                        }}
                        isLoading={loading}
                      ></Button>{' '}
                    </InputRightElement>
                  </InputGroup>
                  <VStack w="100%" align="center">
                    <Tabs w="100%" defaultIndex={0} colorScheme="twitter">
                      <TabList w="100%">
                        <Tab w="50%" isDisabled={combos && combos?.length > 0 ? false : true} defaultIndex={combos && combos?.length > 0 ? 0 : 1}>Combos</Tab>
                        <Tab w="50%" defaultIndex={combos && combos?.length > 0 ? 1 : 0}>Exames</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel w="100%" >
                          <TableContainer minWidth="100%" height="29.02em" overflowY="auto" whiteSpace="none">
                            <Table variant="simple" size="lg" minW="100%" id="table-procedures">
                              <Tbody>
                                <Tr></Tr>
                                {combos && combos?.length > 0 ?
                                  useCombo : <>
                                    <Center>
                                      {/* <Heading textAlign="center" color="red" mt="5" size="md">
                                      <WarningTwoIcon mb="5" color="red" boxSize={20} />
                                    </Heading> */}
                                    </Center>
                                    <chakra.h4 mt="2em" color="#808080" textAlign="center" fontWeight={'bold'} fontSize="1.2em">
                                      Infelizmente não há combos disponíveis para solicitação
                                    </chakra.h4>
                                  </>}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </TabPanel>
                        <TabPanel>
                          {loading ? (
                            <VStack w="100%">
                              <VStack w="53.7%" m="auto" justify="top">
                                <Loading />
                              </VStack>
                            </VStack>
                          ) : textSearch === null || textSearch === undefined || textSearch === '' ? (
                            <>
                              <TableContainer rminWidth="100%" >
                                <Table variant="simple" size="lg" class="table-agendamento-exame">
                                  <Tbody>{useSubgroup}</Tbody>
                                </Table>
                              </TableContainer>
                              <TableContainer minWidth="100%" height="20em" overflowY="auto" whiteSpace="none">
                                <Table variant="simple" size="lg" minW="100%" id="table-procedures">
                                  <Tbody>
                                    <Tr></Tr>
                                    {useProcedures}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            <TableContainer minWidth="100%" height="20em" overflowY="auto" whiteSpace="none">
                              <Table variant="simple" size="lg" minW="100%" id="table-procedures">
                                <Tbody>
                                  <Tr></Tr>
                                  {useProcedures}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                    <ButtonGroup pt="5%" pb="5%">
                      <Button
                        colorScheme="teal"
                        w="13em"
                        h="3em"
                        type="submit"
                        borderRadius="2em"
                        isDisabled={user.cart && user.cart.itens && user.cart.itens.length > 0 ? false : true}
                        onClick={() => {
                          modalDisclosure.onOpen();
                        }}
                      >
                        Concluir
                      </Button>
                    </ButtonGroup>
                  </VStack>
                </VStack>
              );
            }}
          </Formik>
        </>)}


    </>
  );
}

export default Procedimentos;
