import { Button, HStack, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { navigate, useLocation, useNavigate } from 'react-router-dom';

import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import TextField from '../../Utils/Inputs/TextField';
import * as Yup from 'yup';
import validaCPF from '../../Utils/Functions/validaCPF';
import { parse } from 'date-fns';
import { AccountContext } from '../../AccountContext';
import MainDrawer from '../MainDrawer'
const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

function EditarMedicoCadastro() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const menuDisclosure = useDisclosure();
  const { setUser, user } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const disclosure = useDisclosure();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [diretor, setDiretor] = useState(false);
  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }
  console.log(user.username)
  function searchCRM(crm) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/search_crm`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          crm: crm,
          empresa_id: user.empresa_id,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Erro na solicitação. Código: ${response.status}`);
          }

          const data = await response.json();

          // Supondo que o servidor envie uma mensagem indicando a existência ou não do CRM
          if (data.message === 'existe' || data.message === 'nao_existe' || data.message === 'cpf & crm já existe' || data.message === 'crm já existe' || data.message === 'cpf já existe') {
            resolve(data.message);
          } else {
            reject(`Resposta inesperada do servidor: ${data.message}`);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function updateMedico(vals) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/clients/updateOperador/`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        nome: vals.nome,
        nascimento: vals.nascimento,
        conselho: vals.conselho,
        cpf: vals.cpf,
        sexo: vals.sexo === 'Masculino' ? 'M' : 'F',
        uf: vals.uf,
        whatsapp: vals.whatsapp,
        operador_id: state.operador_id,
        email: vals.email,
      }),
    })
      .catch((err) => {
        setLoading(false)
        return err;
      })
      .then((response) => {
        if (!response || !response.ok || response.status >= 400) {
          setLoading(false)
        }
        setLoading(true)
        return response.json();
      })
      .then((result) => {
        if (!result) return;
        if (result.status !== 'Algo de errado ocorreu') {
          setLoading(false)

          if (user.username === vals.cpf) {
            if (user.crm !== vals?.conselho && vals.conselho > 0) {
              setUser((prevState) => ({ ...prevState, crm: vals.conselho, qntdeMedico: user.qntdeMedico ? user.qntdeMedico + 1 : 1 }))
              navigate('/validacaobird');
              return
            } else if (user?.crm?.length > 0 && vals?.conselho?.length === 0) {
              setDiretor(true)
              setUser((prevState) => ({ ...prevState, crm: vals.conselho, qntdeMedico: user.qntdeMedico ? user.qntdeMedico - 1 : 0 }))
              menuDisclosure.onOpen()
              return
            }
          }
          navigate('/alerts/sucesso/default');
          return
        }
      });
  }
  return (
    <>
      <NoUserHeader />
      {diretor ? <MainDrawer disclosure={menuDisclosure} /> : <> </>}
      <VStack>
        {' '}
        <Formik
          initialValues={{
            nome: state && state.nome ? state.nome : '',
            nascimento: state && state.nascimento ? state.nascimento.split('T')[0].split('-').reverse().join('/') : '',
            conselho: state && state.conselho ? state.conselho : '',
            cpf: state && state.cpf ? state.cpf : '',
            sexo: state.sexo === 'M' || state.sexo === 'm' ? 'Masculino' : state.sexo === 'F' || state.sexo === 'f' ? 'Feminino' : '',
            uf: state && state.uf ? state.uf : '',
            whatsapp: state && state.whatsapp ? state.whatsapp : '',
            email: state && state.email ? state.email : '',
          }}
          validationSchema={Yup.object({
            nome: Yup.string()
              .required('Nome é obrigatório')
              .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'Nome não deve conter números, aspas, pontos ou vírgulas')
              .max(50, 'O nome não pode ter mais de 50 caracteres'),
            whatsapp: Yup.string()
              .required('Whatsapp é obrigatório')
              .trim()
              .matches(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/, 'Whatsapp Inválido'),
            email: Yup.string().trim().email('Email inválido'),
            uf: Yup.string()
              .required('Campo obrigatório')
              .matches(/^[^\d]+$/, 'Escreva a UF sem acentos ou caracteres especiais')
              .max(2, 'A UF não pode conter mais de 2 caracteres'),
            nascimento: Yup.date()
              .transform((value, originalValue) => {
                return parse(originalValue, 'dd/MM/yyyy', new Date());
              })
              .required('Data de nascimento é obrigatória!')
              .typeError('Data inválida, digite uma data no formato DD/MM/AAAA'),

          })}
          validateOnChange={false}
          onSubmit={(values, actions) => {
            const vals = { ...values };
            const conselho = vals.conselho
            // if (state.perfil_id === 10 && !state.conselho || state.perfil_id === 10 && state.conselho !== null) {
            //   setLoading(true)
            //   updateMedico(vals)
            //   return
            // }
            if (state.conselho === conselho) {
              setLoading(true)
              updateMedico(vals)
              return
            }

            if (conselho) {
              searchCRM(conselho)
                .then((resposta) => {
                  setLoading(true)
                  if (resposta === 'cpf & crm já existe') {
                    setLoading(false);
                    callToast('Importante', 'CRM e CPF já existe em nossa base com um operador ativo ', 'error');
                    return
                  } else if (resposta === 'crm já existe') {
                    setLoading(false);
                    callToast('Importante', 'Este CRM já existe em nossa base com um operador ativo ', 'error');
                    return
                  } else if (resposta === 'cpf já existe') {
                    setLoading(false);
                    callToast('Importante', 'Este CPF já existe em nossa base com um operador ativo ', 'error');
                    return
                  } else {
                    updateMedico(vals)
                  }
                })
                .catch((erro) => {
                  setLoading(false)
                  console.error("Erro na pesquisa CRM:", erro);
                });
            } else {
              setLoading(true)
              updateMedico(vals)
            }

          }}
        >
          <VStack as={Form} w="90%" textAlign={'center'}>
            <Text fontSize="1em" lineHeight="1em" fontWeight={'bold'} textAlign={'center'} color="#5AB2AD" noOfLines={1} maxWidth="100%">
              Edite os dados do médico da sua clínica
            </Text>
            <TextField
              label="Nome*"
              name="nome"
              textAlign="left"
              fontSize="0.9em"
              color="#939598"
              backgroundColor="#EFEFEF"
              maskChar={null}
              type="text"
              h="2.8em"
              fontWeight="bold"
              borderRadius="-0.5em"
              autoComplete="off"
              maxLength={50}
            />

            <TextField
              type="email"
              name="email"
              label="Email:"
              placeholder=""
              textAlign="left"
              fontSize="0.7em"
              color="#939598"
              backgroundColor="#EFEFEF"
              maskChar={null}
              h="3.8em"
              fontWeight="bold"
              borderRadius="-0.5em"
              autoComplete="off"
              maxLength={80}
            />

            <HStack>
              <TextField
                name="nascimento"
                label="Data de nascimento*"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                mask="99/99/9999"
                maskChar={null}
                type="tel"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
              />

              <TextField
                name="sexo"
                label="Sexo*"
                textAlign="left"
                fontSize="0.9em"
                color="#000000"
                backgroundColor="#808080"
                maskChar={null}
                type="tel"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
                isDisabled={true}
              />
            </HStack>

            <HStack flexDirection={'row'}>
              <TextField
                name="cpf"
                label="CPF*"
                mask="999.999.999-99"
                textAlign="left"
                fontSize="0.9em"
                color="#000000"
                maskChar={null}
                type="tel"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
                isDisabled
                backgroundColor="#808080"
              />

              {/* {user.perfil_id === 10 && !user.crm || user.perfil_id === 10 && user.crm !== null ? (<><></></>) : */}
              <TextField
                name="conselho"
                label="N° CRM*"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                maskChar={null}
                type="tel"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"

              />
              {/* } */}


              <TextField
                name="uf"
                label="UF*"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                maskChar={null}
                type="text"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
              />
            </HStack>

            <HStack w="100%">
              <TextField
                name="whatsapp"
                label="Whatsapp*"
                textAlign="left"
                fontSize="0.9em"
                color="#939598"
                backgroundColor="#EFEFEF"
                maskChar={null}
                mask="(99)99999-9999"
                type="tel"
                h="2.8em"
                fontWeight="bold"
                borderRadius="-0.5em"
                autoComplete="off"
              />
            </HStack>

            <Button
              backgroundColor="#95F3EE"
              color="#5AB2AD"
              h="3em"
              type="submit"
              borderRadius="2em"
              w={{ md: '20em', sm: '15em' }}
              mt={{ md: '30em', sm: '20em' }}
              ml={{ md: '2.5em', sm: '4em' }}
            // isLoading={loading}
            >
              Salvar
            </Button>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default EditarMedicoCadastro;
