import React, { useContext, useState } from 'react';
import NoUserHeader from '../Utils/Headers/NoUserHeader';
import { AccountContext } from '../AccountContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Stack,
  Text,
  VStack,
  Wrap,
  chakra,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import TextField from '../Utils/Inputs/TextField';
import voltar from '../../assets/images/v.svg';
function MensagemRecuperacao() {
  const { state } = useLocation();
  let firstName = state.nome.split(' ');
  const { setUser, setPage } = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [pin, setPin] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const handleChange = (pin) => {
    setPin(pin);
  };
  const [counter, setCounter] = useState(30);
  const [showText, setShowText] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  function callToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 4000,
      isClosable: true,
      position: 'top',
      colorScheme: 'red',
      containerStyle: {
        paddingTop: '50px',
      },
    })
  }

  const contar = () => {
    let aux = 30;
    var countdown = setInterval(function () {
      aux--;
      setCounter(aux);
      if (aux === 0) {
        clearInterval(countdown);
        setShowText(false);
        setDisableButton((disableButton) => false);
        setCounter(30);
      }
    }, 1000);
  };

  function enviarWpp(whatsapp, cpf, nome) {
    fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/recuperacaodesenha/${whatsapp}/${cpf}/${nome}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  setPage('Recuperação');
  return (
    <>
      <VStack align="center" backgroundColor="#E0F0EF" h="100vh" pt={{ md: '30%', sm: '10%' }}>
        <Image mr="18em" src={voltar} onClick={() => navigate(-1)} size="lg"></Image>{' '}
        <Formik
          initialValues={{}}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            const vals = {
              codigo: pin,
              fone: state.whatsapp,
              cpf: state.cpf,
            };
            fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/whatsapp/recuperation_whatsapp`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(vals),
            })
              .catch((err) => {
                return;
              })
              .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                  setError('Erro na Rede');
                  return res.json();
                }
                if (res.status === 200) {
                  setError('');
                }
                return res.json();
              })
              .then((data) => {
                if (!data) return;
                if (data.success === false) {
                  callToast('Importante!', 'Seu código está incorreto. Por favor, tente novamente', 'error');
                  setPin('')
                  return;
                }
                if (data.success === true) {
                  console.log('Sucesso');
                  navigate('/novasenha', {
                    state: {
                      ...state,
                    },
                  });
                }
              });
          }}
        >
          <VStack as={Form} w="70%" pt="6em">
            <chakra.p color="#5AB2AD" fontSize={'1em'} fontWeight={'bold'} paddingBottom="0.5em" textAlign={'justify'}>
              Enviamos por Whatsapp seu código de verificação para o número de final {state.whatsapp && state.whatsapp.slice(-4)}.
            </chakra.p>

            <chakra.h1 fontSize="0.8em" color="#5AB2AD" fontWeight="bold" pt="3em">
              Digite o código
            </chakra.h1>
            <HStack w="100%">
              <PinInput type="alphanumeric" size="md" name="codigo" value={pin} onChange={handleChange} otp>
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
                <PinInputField backgroundColor="#FFFFFF" />
              </PinInput>
            </HStack>

            <ButtonGroup pt={{ md: '21em', sm: '40%' }} sx={{ width: '100%' }}>
              <Button
                type="submit"
                w={{
                  base: '100%',
                }}
                backgroundColor="#5AB2AD"
                color="white"
                borderRadius="0.8em"
                h="2.9em"
              >
                Continuar
              </Button>

            </ButtonGroup>

            <ButtonGroup pt="0.5rem" sx={{ width: '100%' }}>
              <Button
                w={{
                  base: '100%',
                }}
                disabled={disableButton}
                isLoading={disableButton}
                loadingText={'Aguarde' + ' ' + counter + ' ' + 'segundos'}
                onClick={() => {
                  setDisableButton((disableButton) => true);
                  enviarWpp(state.whatsapp, state.cpf, state.nome);
                  setShowText(true);
                  contar();
                }}
              >
                Gerar um novo código
              </Button>
            </ButtonGroup>
          </VStack>
        </Formik>
      </VStack>
    </>
  );
}

export default MensagemRecuperacao;
