import { Button, ButtonGroup, Flex, Heading, Select, Text, VStack, useDisclosure, useToast, chakra, HStack, Spacer } from '@chakra-ui/react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import NoUserHeader from '../../Utils/Headers/NoUserHeader';
import { AccountContext } from '../../AccountContext';
import { CiLogin } from "react-icons/ci";
import ConfirmaMudancaEmpresa from './Modal/ConfirmaMudancaEmpresa';
import Loading from '../../Utils/Loading';

function Main() {
    const { user, setUser, setPage } = useContext(AccountContext);
    const [dados, setDados] = useState([])
    setPage('Mudar empresa')
    const disclosure = useDisclosure()
    const [cnpj_selecionado, setCnpjSelecionado] = useState([])
    const [loading, setLoading] = useState(false)

    function fetchEmpresas() {
        setLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL_API_SERVER}/auth/empresasVinculadas`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username: user.cpf }),
        })
            .catch((err) => {
                return;
            })
            .then((res) => {
                if (!res || !res.ok || res.status >= 400) {
                    return;
                }
                return res.json();
            })
            .then((data) => {
                setLoading(false)
                if (!data) return;
                setDados(data)
            });
    }

    useEffect(() => {
        fetchEmpresas()
    }, [user])
    return (
        <>
            <NoUserHeader />
            <ConfirmaMudancaEmpresa disclosure={disclosure} cnpj={cnpj_selecionado} />
            <Flex
                minW="210px"
                maxW="340px"
                mx="auto"
                overflow="hidden"
                padding="1em"
                px={5}
                pt={5}
            >
                <VStack alignItems="left" spacing={2.5} w={'100%'}>
                    {!loading ?
                        <>
                            <Heading fontSize="16" fontWeight={'bold'} color={'#585858'}>
                                Empresas que você está vinculado:
                            </Heading>

                            <HStack justify="space-between" w="100%" >
                                <Heading fontSize="16" color={'#585858'} fontWeight={'bold'} mt={'1em'} mb={'1em'}>
                                    Dados empresas
                                </Heading>
                            </HStack>
                            {dados
                                .filter((empresa, index, self) =>
                                    index === self.findIndex((t) => (
                                        t.razao_social === empresa.razao_social && t.cnpj === empresa.cnpj
                                    ))
                                )
                                .map((empresa) => {
                                    return (
                                        <>

                                            <chakra.span fontSize="14" color={'#585858'}>Razão social: </chakra.span>
                                            <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}> {empresa.razao_social} </chakra.span>

                                            <HStack justify="space-between" w="90%">

                                                <VStack align="left">
                                                    <chakra.span fontSize="14" color={'#585858'}>CNPJ: </chakra.span>
                                                    <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}> {empresa.cnpj} </chakra.span>
                                                </VStack>
                                                <VStack align="left">
                                                    <chakra.span fontSize="14" color={'#585858'}>Perfil: </chakra.span>
                                                    <chakra.span fontSize="12" color={'#585858'} fontWeight={'bold'}> {empresa.perfil_id === 10 ? 'Médico Gestor' : empresa.perfil_id === 4 ? 'Médico' : 'Gestor'} </chakra.span>
                                                </VStack>
                                                {empresa.cnpj === user.cnpj ? <><chakra.span fontSize="14" color={'red'}> Logado </chakra.span> </> :
                                                    <>
                                                        <CiLogin size={30} color={'teal'} onClick={() => {
                                                            setCnpjSelecionado(empresa.cnpj)
                                                            disclosure.onOpen()
                                                        }} />
                                                    </>}
                                            </HStack >

                                            <Spacer border={'1px solid #f0f0f0'} />


                                        </>
                                    );
                                })
                            }
                        </> :
                        <>
                            <Loading />
                        </>}



                </VStack>
            </Flex >
        </>
    )
}

export default Main
